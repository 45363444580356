import React, { useState, useContext, useEffect } from "react";

import { Avatar, Stack, TextField, Button, Typography } from "@mui/material";

// import axios from "axios";
import CommentIcon from "@mui/icons-material/Comment";

import UserAvatar from "./UserAvatar";

import { UserContext } from "../../App";

import { authBackend } from "../Backend";

import {
  loadRelationships,
  RelationshipAvatar,
} from "../Relationships/RelationshipAvatar";

function CommentEntry({ post, post_id, ProfilePic, onSubmit }) {
  const { userState } = useContext(UserContext);
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");

  const handleCommentChange = (e) => {
    const { name, value } = e.target;
    if (value) {
      setError("");
    } else {
      setError("Text cannot be empty!");
    }
    setComment(value);
  };

  function handleSubmit(e, post_id, comment) {
    if (!comment) {
      setError("Text cannot be empty!");
      return;
    }

    let token = localStorage.token && JSON.parse(localStorage.getItem("token"));

    // const data = {
    //   post: post_id,
    //   message: comment
    // };

    authBackend({
      method: "post",
      path: "comment/",
      payload: {
        post: post_id,
        message: comment,
      },
    })
      .then((data) => {
        setComment("");
        // console.log("CommentEntry handleSubmit response");
        // console.log(data);
        onSubmit(data.result);
      })
      .catch((err) => {
        console.log("handleCommentSubmit ERROR");
        console.log(err);
      });
    // axios
    //   .post(`${url}/api/v1/comment/`, data, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   .then((response) => {
    //     setComment("");
    //     onSubmit(response.data.result);
    //   })
    //   .catch((err) => {
    //     console.log("handleCommentSubmit ERROR");
    //     console.log(err);
    //   });
  }

  return (
    <Stack direction="row" spacing={2} style={{ margin: "8px 8px 8px 8px" }}>
      <RelationshipAvatar
        size={30}
        relationship_id={userState.currentUser.relationship.id}
      />

      {/* <UserAvatar user={userState.currentUser}/> */}
      {/* <Avatar src={ProfilePic} /> */}
      <TextField
        fullWidth
        value={comment}
        // id={post_id}
        multiline
        variant="filled"
        label="Comments, feedback, questions?"
        onChange={handleCommentChange}
        error={error !== ""}
        helperText={error}
      />
      <Button
        // display={false}
        variant="contained"
        onClick={(e) => handleSubmit(e, post.id, comment)}
        startIcon={<CommentIcon />}
      >
        <Typography sx={{ display: { xs: "none", sm: "block" } }}>
          {" "}
          Comment
        </Typography>
      </Button>
    </Stack>
  );
}

export default CommentEntry;
