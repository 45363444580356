import React, { useContext, useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

// import axios from "axios";

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Link,
} from "@mui/material";

import Form from "@rjsf/mui";

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv6";

import { authBackend, publicBackend } from "../Backend";

import jwtDecode from "jwt-decode";
// import { withTheme } from '@rjsf/core';
// import Theme from '@rjsf/mui';
// Make modifications to the theme with your own fields and widgets
// const Form = withTheme(Theme);

import { UIContext, UserContext } from "../../App";

const SetPassword = () => {
  const { uiDispatch } = useContext(UIContext);
  const [loading, setLoading] = useState(false);
  const { userState, userDispatch } = useContext(UserContext);
  const [formData, setFormData] = useState({});

  // const token = localStorage.token && JSON.parse(localStorage.token);
  const navigate = useNavigate();

  let { identifier } = useParams();

  // console.log("NEW PROFILE 3");
  // console.log(identifier);
  const jwtToken = identifier;

  const decodeToken = jwtDecode(jwtToken);
  // console.log("JWT DECODE");
  // console.log(decodeToken);

  var ca = jwtToken;
  var base64Url = ca.split('.')[1];

  var decodedValue = JSON.parse(window.atob(base64Url));

  // console.log("JWT DECODE 2");
  // console.log(decodedValue);


  const schema = {
    title: "Set New Password",
    // "description": "ThinkBeyond Login",
    type: "object",
    required: ["new_password", "confirm_password"],

    properties: {
      new_password: {
        type: "string",
        format: "password",
        title: "New Password",
        minLength: 3,
      },
      confirm_password: {
        type: "string",
        format: "password",
        title: "Confirm Password",
        minLength: 3,
      },
    },
  };

  const ui_schema = {
    email: {
      "ui:autofocus": true,
      // "ui:description": "Login Username",
    },
    "ui:submitButtonOptions": {
      submitText: "Update",
      norender: false,
      props: {
        disabled: false,
        className: "btn btn-info",
      },
    },
  };

  const formSubmit = async ({ formData }, e) => {
    // console.log("Data submitted: ", formData);

    // setLoading(true);

    await publicBackend({
      method: "put",

      path: `registration/setpassword/`,
      payload: { token: jwtToken, ...formData },
    })
      .then((data) => {
        // setLoading(false);

        // console.log("SetPassword formSubmit");
        // console.log(data);

        uiDispatch({
          type: "SET_MESSAGE",
          payload: { color: "success", display: true, text: data.message },
        });

        navigate("/login");
      })
      .catch((err) => {
        // setLoading(false);
        // console.log("SetPassword formSubmit ERROR");
        // console.log(data);

        console.log(err);
        if (err && err.response) {
          if (err.response.status === 422) {
            setError({ ...err.response.data.error });
          }

          if (err.response.status === 400) {
            uiDispatch({
              type: "SET_MESSAGE",
              payload: {
                color: "error",
                display: true,
                text: err.response.data.error,
              },
            });
          }
        }
      });

    // try {
    //   const { data } = await axios.put(
    //     `${url}/api/v1/registration/setpassword/${user_id}`,
    //     formData
    //   );

    //   setLoading(false);

    //   uiDispatch({
    //     type: "SET_MESSAGE",
    //     payload: { color: "success", display: true, text: data.message },
    //   });

    //   navigate("/login");
    // } catch (err) {
    //   setLoading(false);

    //   console.log(err);
    //   if (err && err.response) {
    //     if (err.response.status === 422) {
    //       setError({ ...err.response.data.error });
    //     }

    //     if (err.response.status === 400) {
    //       uiDispatch({
    //         type: "SET_MESSAGE",
    //         payload: {
    //           color: "error",
    //           display: true,
    //           text: err.response.data.error,
    //         },
    //       });
    //     }
    //   }
    // }
  };

  return (
    <>
      <Box
        width={"80vh"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        m="auto"
      >
        <Paper variant="elevation" elevation={2}>
          <Grid
            container
            direction="column"
            justify="center"
            spacing={2}
            padding={2}
          >
            <Grid item>
              <Form
                formData={formData}
                schema={schema}
                uiSchema={ui_schema}
                validator={validator}
                onSubmit={formSubmit}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default SetPassword;
