import React from "react";

import { IconButton } from "@mui/material";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import StopIcon from "@mui/icons-material/Stop";

import { useSpeechSynthesis } from "react-speech-kit";

function Speaker({ message }) {
  const { speak, speaking, cancel, voices } = useSpeechSynthesis();

  const handleSpeak = (textMessage) => {
    const voiceIndex = voices.findIndex(
      (item) => item.name === "Google US English"
    );
    speak({ text: textMessage, voice: voices[voiceIndex] });
  };

  return (
    <IconButton
      aria-label="speak"
      size="large"
      onClick={() => (speaking ? cancel() : handleSpeak(message))}
    >
      {speaking ? <StopIcon /> : <RecordVoiceOverIcon />}
    </IconButton>
  );
}

export default Speaker;
