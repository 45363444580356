import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
  createRef,
  useRef,
} from "react";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { authBackend, publicBackend } from "../Backend";

const queryTexting = async (post_data = {}) => {
  const data = await publicBackend({
    // const { data } = await publicBackend({
    method: "post",
    path: "registration/texting",
    payload: post_data,
  }).then((data) => {
    return data;
  });

  return data.result;
};

export const PhoneNumbersWidget = (props) => {
  const get_value = (prop_key) => {
    const prop_json = JSON.parse(props.value);
    return prop_json[prop_key];
  };

  const put_value = (prop_key, value) => {
    var prop_json = JSON.parse(props.value);
    prop_json[prop_key] = value;

    if (prop_key === "country") {
      prop_json["region"] = " ";
      prop_json["district"] = " ";
      prop_json["area_code"] = " ";
      prop_json["phone_number"] = " ";
    }
    if (prop_key === "region") {
      prop_json["district"] = " ";
      prop_json["area_code"] = " ";
      prop_json["phone_number"] = " ";
    }
    if (prop_key === "district") {
      prop_json["area_code"] = " ";
      prop_json["phone_number"] = " ";
    }
    if (prop_key === "area_code") {
      prop_json["phone_number"] = " ";
    }

    const json_string = JSON.stringify(prop_json);
    props.onChange(json_string);
    return json_string;
  };

  const [countryList, setCountryList] = useState(["Canada", "USA"]);
  const [regionList, setRegionList] = useState([" "]);
  const [districtList, setDistrictList] = useState([" "]);
  const [areaCodeList, setAreaCodeList] = useState([" "]);
  const [phoneNumberList, setPhoneNumberList] = useState([" "]);

  const update_selects = () => {
    const prop_json = JSON.parse(props.value);

    if (props.readonly) {
      setRegionList([prop_json["region"]]);
      setDistrictList([prop_json["district"]]);
      setAreaCodeList([prop_json["area_code"]]);
      setPhoneNumberList([prop_json["phone_number"]]);
      return;
    }

    if (prop_json["country"].length > 1) {
      console.log(`Getting list of regions based on ${prop_json["country"]}`);
    } else {
      setRegionList([" "]);
    }

    if (prop_json["region"].length > 1) {
      console.log(`Getting list of districts based on ${prop_json["region"]}`);
    } else {
      setDistrictList([" "]);
    }

    if (prop_json["district"].length > 1) {
      console.log(
        `Getting list of area codes based on ${prop_json["district"]}`
      );
    } else {
      setAreaCodeList([" "]);
    }

    if (prop_json["area_code"].length > 1) {
      console.log(
        `Getting list of phone numbers based on ${prop_json["area_code"]}`
      );
    } else {
      setPhoneNumberList([" "]);
    }

    // if (prop_json["phone_number"].length === 1) {
    if (phoneNumberList.length === 1) {
      queryTexting(prop_json).then((res) => {

        if (res.region.length > 0) {
          setRegionList(res.region);
        }
        if (res.district.length > 0) {
          setDistrictList(res.district);
        }
        if (res.area_code.length > 0) {
          setAreaCodeList(res.area_code);
        }
        if (res.phone_number.length > 0) {
          const current_number = prop_json["phone_number"];
          if (current_number !== " " && !(current_number in res.phone_number)) {
            setPhoneNumberList([current_number, ...res.phone_number]);
          } else {
            setPhoneNumberList(res.phone_number);
          }
        }
      });
    }
  };

  const get_disabled = (prop_key) => {
    const prop_json = JSON.parse(props.value);
    const prop_disabled = prop_json[prop_key] === "";
    return props.readonly ? true : prop_disabled;
  };

  useEffect(() => {
    update_selects();
  }, [props.value]);

  return (
    <>
      <div>
        <FormControl sx={{ m: 1, minWidth: 170 }}>
          <InputLabel id="countryLabel">Country</InputLabel>
          <Select
            labelId="countryLabel"
            id="countrySelect"
            value={get_value("country")}
            label="Country"
            disabled={props.readonly}
            onChange={(event) => {
              put_value("country", event.target.value);
            }}
          >
            {countryList.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 170 }}>
          <InputLabel id="regionLabel">Province/State</InputLabel>
          <Select
            labelId="regionLabel"
            id="regionLabelSelect"
            value={get_value("region")}
            disabled={get_disabled("region")}
            label="State/Province"
            onChange={(event) => {
              put_value("region", event.target.value);
            }}
          >
            {regionList.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div>
        <FormControl sx={{ m: 1, minWidth: 170 }}>
          <InputLabel id="districtLabel">Municipality</InputLabel>
          <Select
            labelId="districtLabel"
            id="districtLabelSelect"
            value={get_value("district")}
            disabled={get_disabled("district")}
            label="Municipality"
            onChange={(event) => {
              put_value("district", event.target.value);
            }}
          >
            {districtList.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 100 }}>
          <InputLabel id="areaCodeLabel">Area Code</InputLabel>
          <Select
            labelId="areaCodeLabel"
            id="areaCodeSelect"
            value={get_value("area_code")}
            disabled={get_disabled("area_code")}
            label="Area Code"
            onChange={(event) => {
              put_value("area_code", event.target.value);
            }}
          >
            {areaCodeList.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <FormControl sx={{ m: 1, minWidth: 300 }}>
        <InputLabel id="phoneNumbers">Phone Number *</InputLabel>
        <Select
          labelId="phoneNumberLabel"
          id="phoneNumberSelect"
          disabled={get_disabled("phone_number")}
          value={get_value("phone_number")}
          label="Phone Number"
          onChange={(event) => {
            put_value("phone_number", event.target.value);
          }}
        >
          {phoneNumberList.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
        {!props.readonly && (
          <FormHelperText>
            Please select a text number that can be used to make log posts for
            your dependent senior with no app required.
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};
