import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
  createRef,
  useRef,
} from "react";

import PeopleIcon from "@mui/icons-material/People";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import PersonIcon from "@mui/icons-material/Person";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import StarsIcon from "@mui/icons-material/Stars";

import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

import Icon from "@mui/material/Icon";

// import { ReactComponent as Logo } from "./icons/diversity_1_24px.svg";

import { UserContext } from "../../App";

// import SvgIcon from "@mui/material/SvgIcon";

// const Diversity1Icon = (props) => {
//   return (
//     <SvgIcon {...props}>
//       <Logo />
//     </SvgIcon>
//   );
// };

export const RelationshipFeatureOptions = [
  { const: 0, title: "No" },
  { const: 1, title: "View" },
  { const: 2, title: "Edit" },
];

export const RelationShipAvatars = [
  { name: "PersonIcon", icon: PersonIcon },
  { name: "PeopleIcon", icon: PeopleIcon },
  { name: "Diversity1Icon", icon: Diversity1Icon },
  { name: "Diversity2Icon", icon: Diversity2Icon },
  { name: "Diversity3Icon", icon: Diversity3Icon },
  { name: "EmojiPeopleIcon", icon: EmojiPeopleIcon },
  { name: "LocalHospitalIcon", icon: LocalHospitalIcon },
  { name: "LocalPoliceIcon", icon: LocalPoliceIcon },
  { name: "VolunteerActivismIcon", icon: VolunteerActivismIcon },
  { name: "StarsIcon", icon: StarsIcon },
];

export const RelationshipAvatar = ({
  relationship_id,
  label,
  size = 10,
  config,
  configString = "",
}) => {
  const [avatar, setAvatar] = useState();

  const { userState } = useContext(UserContext);

  useEffect(() => {
    if (relationship_id) {
      const my_relationship = userState.relationships.find(
        (x) => x.id == relationship_id
      );
      // const icon = JSON.parse(my_relationship.icon);
      // const avatar_icon = RelationShipAvatars.find((x) => x.name == icon.name);
      // setAvatar({
      //   name: my_relationship.name,
      //   color: icon.color,
      //   icon: avatar_icon.icon,
      // });

      // var avatar_icon = RelationShipAvatars.find((x) => x.name == my_relationship);
    } else if (config || configString) {
      var avatar_config = config;
      if (configString !== "") {
        avatar_config = JSON.parse(configString);
      }

      const avatar_name = avatar_config.name;
      var avatar_icon = RelationShipAvatars.find((x) => x.name == avatar_name);
      setAvatar({
        name: label,
        // name: avatar_config.name,
        color: avatar_config.color,
        icon: avatar_icon.icon,
      });
    }
  }, [config]);

  return (
    <>
      {avatar && (
        <Tooltip title={avatar.name} placement="top">
          <div>
            <Icon
              sx={{ fontSize: size, fill: avatar.color }}
              component={avatar.icon}
            />
          </div>
        </Tooltip>
      )}
    </>
  );
};
