import { useState } from "react";
import { TextField, Button, DialogActions, Grid, Box } from "@mui/material";
import { Scheduler } from "@aldabil/react-scheduler";

import { confirmPublicEvent } from "./useSchedule";

const PublicEventWidget = ({ event, onClose, onConfirm, action }) => {
  // const event = scheduler.edited;
  if (event == null) {
    onClose();
  }

  const [error, setError] = useState("");

  const [state, setState] = useState({
    title: "",
    repeat: event?.repeat || getInitialRepeat(event.start, event.end),
    description: "",
    start: event.start,
    end: event.end,
    // start: event?.start,
    // end: event?.end,
    db_id: event?.db_id || undefined,
    duration: (event.end - event.start) / 1000,
    type: 2,
    sharing:
      action === "edit"
        ? event.sharing
        : {
            share_everyone: false,
            share_relationships: ",,",
            share_users: ",,",
          },
  });

  const handleChange = (value, name) => {
    setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = async () => {
    // Your own validation
    if (state.title.length < 2) {
      return setError("Min 2 letters");
    }

    try {
      // scheduler.loading(true);

      confirmPublicEvent(
        {
          event_id: event ? event.event_id : 1,
          db_id: event ? event.db_id : 1,
          start: state.start,
          end: state.end,
          title: state.title,
          description: state.description,
          duration: state.duration,
          repeat: state.repeat,
          type: state.type,
          sharing: state.sharing,
        },
        action
      ).then((data) => {
        if (onConfirm) {
          onConfirm(data, action);
        }
        onClose();
      });
    } finally {
      // scheduler.loading(false);
    }
  };

  return (
    <Box sx={{ m: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p>Please enter details about your visit...</p>
          <TextField
            label="Your Name"
            value={state.title}
            onChange={(e) => handleChange(e.target.value, "title")}
            error={!!error}
            helperText={error}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            value={state.description}
            onChange={(e) => handleChange(e.target.value, "description")}
            fullWidth
          />
        </Grid>{" "}
        <Grid item xs={12}>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Confirm</Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PublicEventWidget;
