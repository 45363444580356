import React from "react";

import { purple, pink } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";

import FolderIcon from "@mui/icons-material/Folder";
import PageviewIcon from "@mui/icons-material/Pageview";
import AssignmentIcon from "@mui/icons-material/Assignment";

import PeopleIcon from '@mui/icons-material/People';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
// import Diversity1Icon from '@mui/icons-material/Diversity1';
// import Diversity3Icon from '@mui/icons-material/Diversity3';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import PersonIcon from '@mui/icons-material/Person';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import StarsIcon from '@mui/icons-material/Stars';

const UserAvatar = ({user}) => {

    var usericon;
    var userstyle;
    if (user.relationship.id === 1) { // POA
        usericon = <LocalPoliceIcon />
        userstyle = { bgcolor: purple[400] }
    }
    else if (user.relationship.id === 2){ // Family
        usericon = <StarsIcon />
        userstyle = { bgcolor: purple[400] }

    }
    else if (user.relationship.id === 3){ // Friend
        usericon = <EmojiPeopleIcon />
        userstyle = { bgcolor: purple[400] }

    }
    else if (user.relationship.id === 4){ // CareGiver
        usericon = <VolunteerActivismIcon />
        userstyle = { bgcolor: purple[400] }

    }
    else if (user.relationship.id === 5){ // Medical
        usericon = <LocalHospitalIcon />
        userstyle = { bgcolor: purple[400] }

    }
    else { // Default
        usericon = <PersonIcon />
        userstyle = { bgcolor: purple[400] }

    }

  return (
    <Avatar sx={userstyle}>
        {usericon}
    </Avatar>
  );
};

export default UserAvatar;
