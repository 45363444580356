import { authBackend } from "../Backend";

export const fetchCurrentUser = async () => {

  const recipient_id = JSON.parse(localStorage.recipient_id);
  
  return await authBackend({
    path: "me/",
    filters: [{ col: "recipient_id", opr: "eq", value: recipient_id }],
  })
    .then((data) => {
      // setPages(data);
      return { data };
    })
    .catch((err) => {
      return err;
    });
};

export const LogoutUser = async () => {
  return await authBackend({
    path: "user/logout/",
  })
    .then((data) => {
      console.log("Logging out user.");
      console.log(data);
      localStorage.removeItem("token");
      return { data };
    })
    .catch((err) => {
      console.log("Logout ERROR!");
      console.log(err);

      localStorage.removeItem("token");
      return err;
    });
};
