import React, { useEffect, useState, useContext, useRef } from "react";
import { authBackend } from "../Backend";

export async function loadUsers() {
  // if (token) {
  // const currentUser = await fetchCurrentUser();

  // const querystring = rison.encode({ columns: qcolumns });
  // console.log("Querystring", querystring);

  // http://localhost:5000/api/v1/example/private

  //   const querystring = rison({columns: columns});
  //   console.log("Querystring", querystring);
  const recipient_id = JSON.parse(localStorage.recipient_id);

  return await authBackend({
    path: "user/",
    filters: [{ col: "recipient_id", opr: "eq", value: recipient_id }],
  })
    .then((data) => {
      // setPages(data);
      // console.log("RESULT OUT", result_out);
      // setData({ ...data, result: result_out });

      const users_data = data.result.map((item, index) => {
        return {...item, id: data.ids[index]}
      })

      return {
        ...data,
        result: users_data.sort(function (first, second) {
          return first.id - second.id;
          // return first.first_name - second.first_name;
        }),
      };
    })
    .catch((err) => {
      return err;
    });

  //     try {

  //   const { data } = await axios.get(
  //     // `${url}/api/v1/documents/?q=${querystring}`,
  //     `${url}/api/v1/user/`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     }
  //   );
  //   if (data) {
  //     console.log("USERS TABLE RESPONSE");
  //     console.log(data);
  //     const result_out = data.result.map((item, index) => ({
  //       ...item,
  //       id: data.ids[index],
  //       // document_type: item.document_type.name,
  //     }));
  //     console.log("RESULT OUT", result_out);
  //     setData({ ...data, result: result_out });
  //     return {
  //       data,
  //     };
  //   }
  // } catch (err) {
  //   if (err && err.response) {
  //     return {
  //       error: err.response.data.error,
  //     };
  //   }
  // }
  // }
}
