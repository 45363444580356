import { useState, useEffect } from "react";
import {
  TextField,
  Button,
  DialogActions,
  Grid,
  Box,
  FormControl,
  Stack,
  InputAdornment
} from "@mui/material";

import {
  SelectField,
  DateField,
  IntervalField,
  WeekdayField,
  RangeField,
} from "./ScheduleFields";

import {
  frequencies,
  endsCriteria,
  getMonths,
  weekdays,
  weekOfMonth,
  generateRRule,
  generateRRuleSet,
  calcUntil,
} from "./useRepeat";

export const RepeatFields = ({ value, onChange }) => {
  const [repeat, setRepeat] = useState(value);

  const onPropChange = (propValue, prop) => {
    var newRepeat = { ...repeat };

    if (prop == "weekdays") {
      // toggle the weekdays button
      var newValue = repeat.weekdays;
      newValue[propValue] = !newValue[propValue];
      if (!newValue.includes(true)) {
        // ensure at least one
        newValue[0] = true;
      }
      newRepeat[prop] = newValue;
    } else {
      newRepeat[prop] = propValue;
    }

    if (newRepeat.freq > 0) {

      const rruleSet = generateRRuleSet(newRepeat);
      newRepeat.rruleSet = rruleSet;
      newRepeat.ics = rruleSet.toString();

      if (newRepeat.criterion == 1) {
        repeat.until = calcUntil(
          newRepeat.dtstart,
          newRepeat.freq,
          newRepeat.interval,
          newRepeat.count
        );
      } 

    } else {
      newRepeat.ics = "";
    }

    // newRepeat.ics = newRepeat.rruleSet.toString()
    // console.log("UPDATE REPEAT ", repeat, newRepeat);
    // console.log("EVENTS", newRepeat.rruleSet.all());
    // setRepeat(newRepeat);

    setRepeat(newRepeat);
  };

  useEffect(() => {
    // if (repeat.freq > 0) {
    //   onChange(repeat);
    // } else {
    //   onChange("");
    // }
    onChange(repeat);
  }, [repeat]);

  return (
    <>
      <Grid item xs={repeat.freq === 0 ? 12 : 3}>
        <SelectField
          label={"Repeat"}
          config={repeat}
          prop={"freq"}
          onChange={onPropChange}
          selectList={frequencies}
        />
      </Grid>
      <Grid item xs={9}>
        {/* {repeat.freq == 1 && ( // Yearly on
          <>
            <Stack direction="row" spacing={2}>
              <SelectField
                label={"the Month of"}
                config={repeat}
                prop={"bymonth"}
                onChange={onPropChange}
                selectList={getMonths()}
              />
              <RangeField
                label={"Day"}
                config={repeat}
                prop={"bymonthday"}
                onChange={onPropChange}
                size={31}
              />
            </Stack>
          </>
        )} */}
        {/* {repeat.freq == 2 && ( // "Yearly on the"
          <>
            <Stack direction="row" spacing={2}>
              <SelectField
                label={"Week"}
                config={repeat}
                prop={"bysetpos"}
                onChange={onPropChange}
                selectList={weekOfMonth}
              />
              <SelectField
                label={"Week Day"}
                config={repeat}
                prop={"weekday"}
                onChange={onPropChange}
                selectList={weekdays}
              />
              <SelectField
                label={"of the Month"}
                config={repeat}
                prop={"bymonth"}
                onChange={onPropChange}
                selectList={getMonths()}
              />
            </Stack>
          </>
        )} */}
        {repeat.freq == 1 && ( // "MOnthly on"
          <>
            <Stack direction="row" spacing={2}>
              <IntervalField
                label={"Every"}
                config={repeat}
                prop={"interval"}
                onChange={onPropChange}
                selectList={frequencies}
              />
              <RangeField
                label={"Day"}
                config={repeat}
                prop={"bymonthday"}
                onChange={onPropChange}
                size={31}
              />
            </Stack>
          </>
        )}
        {repeat.freq == 2 && ( // "Monthl on the"
          <>
            <Stack direction="row" spacing={2}>
              <SelectField
                label={"Week"}
                config={repeat}
                prop={"bysetpos"}
                onChange={onPropChange}
                selectList={weekOfMonth}
              />
              <SelectField
                label={"Week Day"}
                config={repeat}
                prop={"weekday"}
                onChange={onPropChange}
                selectList={weekdays}
              />
            </Stack>
          </>
        )}
        {repeat.freq == 3 && ( // "Weekly"
          <>
            <Stack direction="row" spacing={2}>
              <IntervalField
                label={"Every"}
                config={repeat}
                prop={"interval"}
                onChange={onPropChange}
                selectList={frequencies}
              />
              <WeekdayField
                label={"on"}
                config={repeat}
                prop={"weekdays"}
                onChange={onPropChange}
                selectList={weekdays}
              />
            </Stack>
          </>
        )}
        {repeat.freq >= 4 && (
          <IntervalField
            label={"Every"}
            config={repeat}
            prop={"interval"}
            onChange={onPropChange}
            selectList={frequencies}
          />
        )}
      </Grid>
      {repeat.freq != 0 && (
        <>
          <Grid item xs={4}>
            <DateField
              label={"Starting On"}
              config={repeat}
              prop={"dtstart"}
              onChange={onPropChange}
              // maxDate={repeat.dtstart}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectField
              label={"and Ends"}
              config={repeat}
              prop={"criterion"}
              onChange={onPropChange}
              selectList={endsCriteria}
              minDate={repeat.dtstart}
            />
          </Grid>
          <Grid item xs={5}>
            <Stack direction="row" spacing={2}>
              {repeat.criterion === 1 && (
                <FormControl fullWidth>
                  <TextField
                    value={repeat.count}
                    label="Repeating"
                    onChange={(e) => {
                      var value = parseInt(e.target.value, 10);
                      if (value < 1) value = 1;
                      onPropChange(value, "count");
                    }}
                    type="number"
                    sx={{input: {textAlign: "center"}}}
                    InputProps={{
                    endAdornment: <InputAdornment position="end">days.</InputAdornment>, 
                     min: 1                   
                  }}
                    // error={!!error}
                    // helperText={error}
                    fullWidth
                  />
                </FormControl>
              )}
              {repeat.criterion === 0 && (
                <DateField
                  label={"Ends On"}
                  config={repeat}
                  prop={"until"}
                  onChange={onPropChange}
                />
              )}
            </Stack>
          </Grid>
        </>
      )}
    </>
  );
};
