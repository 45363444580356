import React, { useState, useEffect } from "react";

import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

export const PhoneNumberInput = (props) => {
  const [phone, setPhone] = useState("");

  const handleChange = (newPhone, info) => {
    setPhone(newPhone);
    props.onChange(info.numberValue);
  };

  useEffect(() => {
    setPhone(props.value);
  }, [props]);

  return (
    <MuiTelInput
      id={props.id}
      defaultCountry={"CA"}
      onlyCountries={["CA", "US"]}
      value={phone}
      onChange={handleChange}
      label={props.label}
      forceCallingCode
    />
  );
};
