import React, {
  useContext,
  useState,
  createRef,
  useEffect,
  Fragment,
} from "react";

// import axios from "axios";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  IconButton,
  Container,
  Divider,
  Grid,
  Paper,
  FormControl,
  TextField,
  Typography,
  Link,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import Form from "@rjsf/mui";

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";

import { fetchCurrentUser } from "../Auth/AuthService";
import { authBackend } from "../Backend";

import { IconSelectWidget } from "./IconSelectWidget";

import { RelationshipFeatureOptions } from "./RelationshipAvatar";

import jwtDecode from "jwt-decode";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import { UIContext, UserContext } from "../../App";
// const url =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   ":" +
//   process.env.REACT_APP_API_PORT;

const themeOptions = {
  components: {
    MuiButton: {
      // defaultProps: {
      //   size: "medium",
      // },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: "none",
          fontSize: "1rem",
          margin: "0px",
          // padding: "0px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: "0px",
          padding: "0px",
        },
      },
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
  },
};

export const RelationshipsModal = ({
  modalOpen = false,
  modalId = 0,
  modalEdit = false,
  handleClose,
  handleSubmit,
  isPOA = false,
}) => {
  // const { userState, userDispatch } = useContext(UserContext);

  const recipient_id =
    localStorage.recipient_id && JSON.parse(localStorage.recipient_id);
  const initialState = {
    name: "",
    icon: '{"name":"PersonIcon","color":"#d63cd9"}',
    recipient: recipient_id,
    access_logs: 0,
    access_schedule: 0,
  };

  const schema = {
    type: "object",
    required: ["name", "icon"],
    properties: {
      name: {
        type: "string",
        title: "Name",
        minLength: 2,
      },
      icon: {
        type: "string",
        title: "Icon",
        // minLength: 3,
        default: '{"name":"PersonIcon","color":"#d63cd9"}',
      },
      access_logs: {
        type: "number",
        title: "Logs Access?",
        oneOf: RelationshipFeatureOptions,
        default: 0,
      },
      access_schedule: {
        type: "number",
        title: "Schedule Access?",
        oneOf: RelationshipFeatureOptions,
        default: 0,
      },
    },
  };

  const ui_schema = {
    name: {
      "ui:autofocus": true,
      "ui:readonly": isPOA,
    },
    icon: {
      "ui:widget": "IconSelectWidget",
    },
    access_logs: {
      "ui:readonly": isPOA,
    },

    access_schedule: {
      "ui:readonly": isPOA,
    },
  };

  const { uiDispatch } = useContext(UIContext);
  const [loading, setLoading] = useState(false);
  const [currentData, setCurrentData] = useState(initialState);
  const [validated, setValidated] = useState(false);

  const token = localStorage.token && JSON.parse(localStorage.token);

  async function loadCurrentRelationship() {
    if (modalEdit && token) {
      const recipient_id = JSON.parse(localStorage.recipient_id);

      await authBackend({
        path: `relationship_type/${modalId}`,
        filters: [{ col: "recipient_id", opr: "eq", value: recipient_id }],
      })
        .then((data) => {
          setCurrentData(data.result);
          console.log("CURRENT DATA", data.result);
          if (data.result.name === "POA") {
            console.log("SET POA");
            setIsPOA(true);
          }

          return data;
        })
        .catch((err) => {
          return err;
        });
    }
  }

  useEffect(() => {
    if (modalEdit) {
      loadCurrentRelationship();
    } else {
      setCurrentData(initialState);
    }
  }, [modalOpen]);

  const handleChange = ({ formData }, e) => {
    setCurrentData({ ...currentData, ...formData });
  };

  const formSubmit = async () => {
    if (formRef.current.validateForm()) {
      setLoading(true);

      const recipient_id =
        localStorage.recipient_id && JSON.parse(localStorage.recipient_id);

      var submitMethod;
      var submitPath;
      if (modalEdit) {
        submitMethod = "put";
        submitPath = `relationship_type/${modalId}`;
      } else {
        submitMethod = "post";
        submitPath = "relationship_type/";
      }

      await authBackend({
        method: submitMethod,
        path: submitPath,
        payload: { recipient: recipient_id, ...currentData },
      })
        .then((data) => {
          setLoading(false);

          if (data.error !== undefined) {
            uiDispatch({
              type: "SET_MESSAGE",
              payload: {
                color: "error",
                display: true,
                text: data.error,
              },
            });
            handleSubmit();
          } else {
            // uiDispatch({
            //   type: "SET_MESSAGE",
            //   payload: { color: "success", display: true, text: data.message },
            // });
            handleSubmit(data.result);
          }
        })
        .catch((err) => {
          setLoading(false);

          console.log("formSubmit ERROR");
          console.log(err.message);
          if (err && err.response) {
            if (err.response.status === 422) {
              setError({ ...err.response.message });
            }

            if (err.response.status === 420) {
              uiDispatch({
                type: "SET_MESSAGE",
                payload: {
                  color: "error",
                  display: true,
                  text: err.response.message,
                },
              });
            }
          }
        });
    }
  };

  const formRef = createRef();

  const theme = createTheme(themeOptions);

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        > */}
        {modalOpen && (
          <Box
            width={"100%"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            m="auto"
          >
            <Paper variant="elevation" elevation={2}>
              <Grid
                container
                direction="column"
                justify="center"
                spacing={2}
                padding={2}
              >
                <Grid item>
                  <Box
                    sx={{ display: "flex", flexDirection: "row" }}
                    // sx={{ display: "flex", flexDirection: "row", pt: 2, pl: 2 }}
                  >
                    <Typography variant="h5" gutterBottom>
                      {modalEdit ? "Edit Relationship" : "Add Relationship"}
                    </Typography>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <IconButton
                      color="primary"
                      aria-label="add to shopping cart"
                      onClick={handleClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Grid>

                <Grid item>
                  <Form
                    formData={currentData}
                    schema={schema}
                    uiSchema={ui_schema}
                    validator={validator}
                    onChange={handleChange}
                    widgets={{
                      IconSelectWidget: IconSelectWidget,
                    }}
                    showErrorList="none"
                    ref={formRef}
                  >
                    <Fragment />
                  </Form>
                </Grid>
                <Grid item></Grid>
                <Box
                  sx={{ display: "flex", flexDirection: "row", pt: 2, pl: 2 }}
                >
                  <Button
                    onClick={handleClose}
                    sx={{ margin: 1 }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                    onClick={formSubmit}
                    // disabled={!validated}
                    sx={{ margin: 1 }}
                    variant="contained"
                  >
                    {modalEdit ? "Update" : "Submit"}
                  </Button>
                </Box>
              </Grid>
            </Paper>
          </Box>
        )}
        {/* </Modal> */}
      </ThemeProvider>
    </>
  );
};
