import React, { useState, useContext, useEffect } from "react";

import { UserContext } from "../../App";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";

import { RelationshipAvatar } from "./RelationshipAvatar";
import { RelationshipsModal } from "./RelationshipsModal";

import { loadRelationships } from "../Relationships/useRelationships";

export default function DenseTable() {
  const { userState, userDispatch } = useContext(UserContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalId, setModalId] = useState(0);
  const [modalEdit, setModalEdit] = useState(false);

  const [poaId, setPoaId] = useState();

  const handleEditRecord = (selectedId) => {
    setModalEdit(true);
    setModalId(selectedId);
    setModalOpen(true);
  };

  const handleAddRecord = () => {
    setModalEdit(false);
    setModalOpen(true);
  };

  const handleOpenModal = () => {
    setModalEdit(false);
    setModalId(0);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmitModal = () => {
    loadRelationships().then((data) => {
      userDispatch({
        type: "SET_RELATIONSHIPS",
        payload: data.result,
      });
    });
    setModalOpen(false);
  };

  useEffect(() => {
    console.log("RELATIONSHIPS", userState.relationships);
    const POAfind = userState.relationships.find(({ name }) => name === "POA");
    console.log("POAFIND", POAfind.id);
    setPoaId(POAfind.id);
  }, []);

  return (
    <>
      <RelationshipsModal
        modalOpen={modalOpen}
        modalId={modalId}
        modalEdit={modalEdit}
        handleSubmit={handleSubmitModal}
        handleClose={handleCloseModal}
        isPOA={poaId === modalId}
      />
      {!modalOpen && (
        <Box
          // width={"100%"}
          // display="flex"
          alignItems="center"
          justifyContent="center"
          // m="auto"
        >
          <Paper variant="elevation" elevation={2}>
            <Grid
              container
              direction="column"
              justify="center"
              // spacing={2}
              padding={2}
            >
              <Grid item>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h5" gutterBottom>
                    Relationships
                  </Typography>
                  {/* <Button variant="contained" onClick={handleAddRecord}>
                    Add
                  </Button> */}
                </Stack>
              </Grid>
              <Grid item>
                <TableContainer style={{ maxHeight: 600 }} sx={{ mt: 3.5 }} component={Paper}>
                  {/* <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table"> */}
                  <Table stickyHeader size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Relationship</TableCell>
                        <TableCell>Icon</TableCell>
                        {/* <TableCell width={50}>Actions</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userState.relationships.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>
                            <RelationshipAvatar
                              size={30}
                              configString={row.icon}
                            />
                          </TableCell>
                          {/* <TableCell width={50} align="right">
                            <Stack direction="row">
                              <IconButton
                                aria-label="edit"
                                onClick={() => {
                                  handleEditRecord(row.id);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Stack>
                          </TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
    </>
  );
}
