import { authBackend, publicBackend } from "../Backend";

import { parseISO, addDays } from "date-fns";

import {
  getInitialRepeat,
  parseICS,
  getRRuleStarts,
  calcUntil,
  maxDate
} from "./useRepeat";

import LoopIcon from "@mui/icons-material/Loop";

// export const colorMap = { 1: "", 2: "green", 3: "orange", 4: "gray" };

export const categories = [
  { value: 1, name: "General", color: "" },
  { value: 2, name: "Social", color: "green" },
  { value: 3, name: "Healthcare", color: "orange" },
  { value: 4, name: "Available", color: "gray" },
];

export const getCategory = (id) => {
  return categories.find((category) => category.value === id);
};

export const getCategoryIndex = (id) => {
  return categories.findIndex((category) => category.value === id);
};

export const eventFields = [
  { name: "db_id", type: "hidden" },
  { name: "repeat", type: "hidden" },
  { name: "duration", type: "hidden" },
  {
    name: "type",
    type: "select",
    options: categories,
    default: 1,
    config: {
      label: "Type",
      required: true,
      errMsg: "Plz Select Event Type",
    },
  },
  {
    name: "description",
    type: "input",
    default: "",
    config: { label: "Details", multiline: true, rows: 4 },
  },
];

export const confirmPublicEvent = async (event, action) => {
  const recipient_id = JSON.parse(localStorage.recipient_id);

  // This was an available event, and now its a public event.

  if (action === "edit") {
    // console.log("EXCLUDE DATE", event.start);
    var icsString = "";
    if (event.repeat.ics !== "") {
      const rruleSet = event.repeat.rruleSet;

      var today = new Date();
      const today_offset = today.getTimezoneOffset();
      const gmt_offset = event.start.getTimezoneOffset();
      const tz_offset = (today_offset - gmt_offset) / 60
      var new_date = new Date(event.start)
      new_date.setHours(new_date.getHours() + tz_offset)

      rruleSet.exdate(new_date);
      icsString = rruleSet.toString();

      return publicBackend({
        method: "post",
        path: `calendar/`,
        payload: {
          recipient: recipient_id,
          description: event.description || "",
          start: event.start,
          end: event.end,
          title: event.title || "",
          repeat: false,
          ics: "",
          share_everyone: event.sharing.share_everyone,
          share_relationships: event.sharing.share_relationships,
          share_users: event.sharing.share_users,
          type: 2, // set event to Social event.
        },
      }).then((data) => {
        // update the ICS with the exception event.
        return publicBackend({
          method: "put",
          path: `calendar/${event.db_id}`,
          payload: {
            ics: icsString,
          },
        }).then((data) => {
          return {
            event_id: event.event_id,
            title: "Reserved!",
            start: parseISO(data.result.start),
            end: parseISO(data.result.end),
            type: 2,
            status: 1,
            disabled: true,
          };
        });
      });
    } else {
      return publicBackend({
        method: "put",
        path: `calendar/${event.db_id}`,
        payload: {
          recipient: recipient_id,
          description: event.description || "",
          start: event.start,
          end: event.end,
          title:
            typeof event.title === "string"
              ? event.title
              : event.title.props?.children[2],
          repeat: false,
          ics: "",
          type: 2, // set event to Social event.
        },
      }).then((data) => {
        return {
          event_id: event.event_id,
          title: "Reserved!",
          start: parseISO(data.result.start),
          end: parseISO(data.result.end),
          type: 2,
          status: 1,
          disabled: true,
        };
      });
    }
  }
};

export async function fetchPublicEvents(queryData) {
  // Expected query =
  // ?start=Sun Dec 11 2022 00:00:00 GMT-0500 (Eastern Standard Time)&end=Sat Dec 17 2022 23:59:59 GMT-0500 (Eastern Standard Time)

  // const queryData = fetchQueryToDates(query);

  const recipient_id = JSON.parse(localStorage.recipient_id);

  const startDate = new Date(queryData.start);
  const endDate = new Date(queryData.end);

  return publicBackend({
    path: "calendar/",
    filters: [
      {
        col: "end",
        opr: "gt",
        value: startDate.toISOString(),
      },
      {
        col: "start",
        opr: "lt",
        value: endDate.toISOString(),
      },
      { col: "recipient", opr: "rel_o_m", value: recipient_id },
    ],
  }).then((data) => {
    return dbToSchedulerEvents(data, startDate, endDate);
  });
}

export const getEventTypes = () => {
  return authBackend({
    path: "event_type/",
  }).then((res) => {
    const sorted = res.data.result.sort(function (first, second) {
      return first.key - second.key;
    });
  });
};

export const fetchQueryToDates = (query) => {
  // Expected query =
  // ?start=Sun Dec 11 2022 00:00:00 GMT-0500 (Eastern Standard Time)&end=Sat Dec 17 2022 23:59:59 GMT-0500 (Eastern Standard Time)

  // console.log("FETCH QUERY", query);

  var queryData = {};
  var pairs = (query[0] === "?" ? query.substr(1) : query).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    queryData[pair[0]] = pair[1];
  }

  return queryData;
};

export const fetchRemote = async (queryData) => {
  // const queryData = fetchQueryToDates(query);

  return await fetchEvents(queryData.start, queryData.end);
};

export const fetchEvents = async (startDateString, endDateString) => {

  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  let recipient_id = JSON.parse(localStorage.recipient_id);

  const queryStart = startDate.toISOString();
  const queryEnd = endDate.toISOString();

  return authBackend({
    path: "event/",
    filters: [
      {
        col: "end",
        opr: "gt",
        value: queryStart,
      },
      {
        col: "start",
        opr: "lt",
        value: queryEnd
      },
      { col: "recipient", opr: "rel_o_m", value: recipient_id },
    ],
  }).then((data) => {
    // console.log("DATA", data);

    return dbToSchedulerEvents(data, startDate, endDate);
  });
};

const dbToSchedulerEvents = (data, startDate, endDate) => {
  var events = [];

  var eventId = 1;
  data.result.forEach((item, index) => {
    // console.log("SCHEDULER ITEM",item)
    if (item.ics) {
      const repeat = parseICS(item.ics);
      // getRRuleStarts(repeat, startDate, endDate).forEach(
        getRRuleStarts(repeat, startDate, addDays(endDate, 2)).forEach(
        (ruleStart, ruleindex) => {

          var eventStart = new Date(Date.parse(ruleStart));
          var eventEnd = new Date(
            Date.parse(ruleStart) + item.duration * 1000 
          );

          const apply_fix = true;
          if (apply_fix === true) {
            var today = new Date();
            const today_offset = today.getTimezoneOffset();
            const gmt_offset = eventStart.getTimezoneOffset();
            const tz_offset = (today_offset - gmt_offset) * 60000
            if ((eventStart.getUTCHours() >= 0) && (eventStart.getUTCHours() < (today_offset / 60))) {
              eventStart.setDate(eventStart.getDate() - 1);
              eventEnd.setDate(eventEnd.getDate() - 1);
            }
            eventStart.setHours(eventStart.getHours() - tz_offset)
            eventEnd.setHours(eventEnd.getHours() - tz_offset)
          }

          const ruleEvent = {
            event_id: eventId++,
            db_id: data.ids[index],
            repeat: repeat,
            title: (
              <>
                <LoopIcon /> {item.title || ""}
              </>
            ),
            duration: item.duration,
            start: parseISO(eventStart.toISOString()),
            end: parseISO(eventEnd.toISOString()),
            color: getCategory(item.type.id).color,
            description: item.description || "",
            type: getCategoryIndex(item.type.id),
            status: 1,
            sharing: {
              share_everyone: item.share_everyone,
              share_relationships: item.share_relationships || ",,",
              share_users: item.share_users || ",,",
            },
          };
          // console.log("RULE EVENT", ruleEvent);
          events.push(ruleEvent);
        }
      );
    } else {
      const repeat = getInitialRepeat(parseISO(item.start), parseISO(item.end));
      const event = {
        event_id: eventId++,
        db_id: data.ids[index],
        repeat: repeat,
        title: item.title || "",
        start: parseISO(item.start),
        end: parseISO(item.end),
        duration: item.duration,
        color: getCategory(item.type.id).color,
        description: item.description || "",
        type: getCategoryIndex(item.type.id),
        status: 1,
        sharing: {
          share_everyone: item.share_everyone,
          share_relationships: item.share_relationships || ",,",
          share_users: item.share_users || ",,",
        },
      };
      // console.log("ADDING EVENT", event);
      events.push(event);
    }
  });

  // console.log("EVENTS ", events);

  return events;
};

export const confirmEvent = async (event, action) => {
  const recipient_id = JSON.parse(localStorage.recipient_id);


  const submitMethod = action === "edit" ? "put" : "post";
  const submitId = action === "edit" ? `${event.db_id}` : "";

  const isRepeat = event.repeat.freq > 0;
  const repeatStart = event.repeat.dtstart;

  // console.log("CONFIRM EVENT", event, isRepeat, action);

  var repeatEnd;
  if (isRepeat && event.repeat.criterion == 1) {
    repeatEnd = calcUntil(
      event.repeat.dtstart,
      event.repeat.freq,
      event.repeat.interval,
      event.repeat.count
    );
  } else if (isRepeat && event.repeat.criterion == 2) {
    repeatEnd = maxDate();
  } else {
    if (isRepeat) {
      repeatEnd = event.repeat.until;
    }
  }

  return authBackend({
    method: submitMethod,
    path: `event/${submitId}`,
    payload: {
      recipient: recipient_id,
      description: event.description,
      start: isRepeat ? repeatStart : event.start,
      ics: event.repeat.ics,
      repeat: isRepeat,
      duration: event.duration,
      // duration: (event.end - event.start)/1000,
      end: isRepeat ? repeatEnd : event.end,
      title:
        typeof event.title === "string"
          ? event.title
          : event.title.props?.children[2],
      type: categories[event.type].value,
      share_everyone: event.sharing.share_everyone,
      share_relationships: event.sharing.share_relationships,
      share_users: event.sharing.share_users,
    },
  }).then((data) => {
    // console.log("CONFIRM RETURNING", data);
    return {
      event_id: action === "edit" ? event.event_id : data.id,
      db_id: data.id,
      title: data.result.title,
      start: new Date(data.result.start),
      end: new Date(data.result.end),
      duration: data.duration,
      ics: data.ics,
      repeat: event.repeat,
      color: getCategory(data.result.type).color,
      description: data.result.description,
      type: getCategoryIndex(data.result.type),
      sharing: event.sharing,
      status: 1,
    };
  });
};

export const handleDelete = async (event_id, db_id) => {
  return authBackend({
    method: "delete",
    path: `event/${db_id}`,
  }).then(() => {
    return event_id;
  });
};

export const deleteEvent = async (event_id, db_id) => {
  return authBackend({
    method: "delete",
    path: `event/${db_id}`,
  }).then(() => {
    return {
      event_id: event_id,
      title: "Deleted",
      description: "",
    };
  });
};
