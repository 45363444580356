import React, { useContext, useState, useEffect, createRef } from "react";

// import axios from "axios";

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Link,
} from "@mui/material";

import Form from "@rjsf/mui";

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";

import { fetchCurrentUser } from "../Auth/AuthService";

import jwtDecode from "jwt-decode";
// import { withTheme } from '@rjsf/core';
// import Theme from '@rjsf/mui';
// Make modifications to the theme with your own fields and widgets
// const Form = withTheme(Theme);

import { UIContext, UserContext } from "../../App";
// const url =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   ":" +
//   process.env.REACT_APP_API_PORT;

import { authBackend, publicBackend } from "../Backend";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const themeOptions = {
  typography: {
    fontFamily: "Quicksand,Mulish",
    allVariants: {
      color: "black"
    },
  },
  palette: {
    primary: {
      main: '#87479C',
      dark: '#4C2E87',
      // contrastText: '#242420'
    },
    secondary: {
      main: '#095296',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "1rem",
          margin: "0px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: "0px",
          padding: "0px",
        },
      },
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
  },
};

const Settings = () => {
  const { uiDispatch } = useContext(UIContext);
  const [loading, setLoading] = useState(false);
  const { userState, userDispatch } = useContext(UserContext);
  const [formData, setFormData] = useState({});

  const token = localStorage.token && JSON.parse(localStorage.token);

  const schema = {
    // title: "My Recipient Settings",
    // "description": "ThinkBeyond Login",
    type: "object",
    properties: {
      first_name: {
        type: "string",
        title: "First Name",
        minLength: 3,
      },
      last_name: {
        type: "string",
        title: "Last  Name",
        minLength: 3,
      },
      nickname: {
        type: "string",
        title: "Nickname",
        minLength: 3,
      },
      gender: {
        type: "number",
        title: "Gender",
        // description: "I am one of the following:",
        oneOf: [
          { const: 1, title: "Female" },
          {
            const: 2,
            title: "Male",
          },
        ],
        default: 1,
      },
      language: {
        type: "number",
        title: "Language",
        // description: "I am one of the following:",
        oneOf: [
          { const: 1, title: "English" },
          {
            const: 2,
            title: "French",
          },
        ],
        default: 1,
      },
      birth_date: {
        type: "string",
        title: "Birth Date",
        format: "date",
        default: "1950-01-01",
      },
      subdomain: {
        type: "string",
        title: "Subdomain",
        readOnly: 1,
      },
      post_texting_number: {
        type: "string",
        title: "Post Texting Number",
        readOnly: 1,
      },
    },
  };

  const ui_schema = {
    "ui:submitButtonOptions": {
      submitText: "Update",
      norender: false,
      props: {
        disabled: false,
        className: "btn btn-info",
      },
    },
    // nickname: {
    //   "ui:readonly": true,
    // },
    // post_texting_number: {
    //   "ui:readonly": true,
    // },
  };

  const getRecipient = async () => {
    let token = localStorage.token && JSON.parse(localStorage.token);
    let recipient_id =
      localStorage.recipient_id && JSON.parse(localStorage.recipient_id);
    // console.log("GET RECIPIENT");
    // console.log(recipient_id);

    await authBackend({
      path: `recipient/${recipient_id}`,
    })
      .then((data) => {
        // console.log("getRecipiend");
        // console.log(data);

        return data.result;
      })
      .catch((err) => {
        console.log("getRecipient ERROR");
        console.log(err);
        return err;
      });

    // try {
    //   const { data } = await axios.get(
    //     `${url}/api/v1/recipient/${recipient_id}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    //   if (data) {
    //     return data.result;
    //     // return {
    //     //   data['result']
    //     // };
    //   }
    // } catch (err) {
    //   console.log("ERROR");
    //   if (err && err.response) {
    //     return {
    //       error: err.response.data.error,
    //     };
    //   }
    // }
  };

  useEffect(() => {
    async function loadCurrentRecipient() {
      let recipient_id =
        localStorage.recipient_id && JSON.parse(localStorage.recipient_id);

      await authBackend({
        path: `recipient/${recipient_id}`,
      })
        .then((data) => {
          setFormData(data.result);
        })
        .catch((err) => {
          console.log("getRecipient ERROR");
          console.log(err);
          return err;
        });

      // setFormData({
      //   email: currentUser.data.user.username,
      //   first_name: currentUser.data.user.first_name,
      //   last_name: currentUser.data.user.last_name
      // });
    }

    loadCurrentRecipient();
  }, []);

  const formSubmit = async ({ formData }, e) => {
    // console.log("Data submitted: ", formData);
    if (formRef.current.validateForm()) {
      setLoading(true);
      let token = userState.isLoggedIn ? JSON.parse(localStorage.token) : "";
      let recipient_id =
        localStorage.recipient_id && JSON.parse(localStorage.recipient_id);

      await authBackend({
        method: "put",
        path: `recipient/${recipient_id}`,
        payload: formData,
      })
        .then((data) => {
          // console.log("PUT Recipient Data", data);
          // const recipientData = getRecipient().then((data) => {
          setLoading(false);
          //   console.log("PUT Recipient Data", data);
          setFormData(data.result);

          uiDispatch({
            type: "SET_MESSAGE",
            payload: {
              color: "success",
              display: true,
              text: "Update successful.",
            },
          });
          // });
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);
          if (err && err.response) {
            if (err.response.status === 422) {
              setError({ ...err.response.data.error });
            }

            if (err.response.status === 400) {
              uiDispatch({
                type: "SET_MESSAGE",
                payload: {
                  color: "error",
                  display: true,
                  text: err.response.data.error,
                },
              });
            }
          }
        });

      // try {
      //   const headers = userState.isLoggedIn
      //     ? { headers: { Authorization: `Bearer ${token}` } }
      //     : {};

      //   const { data } = await axios.put(
      //     `${url}/api/v1/recipient/${recipient_id}`,
      //     formData,
      //     headers
      //   );

      //   const recipientData = await getRecipient();
      //   console.log("Recipient Data", recipientData);
      //   setFormData(recipientData);

      //   uiDispatch({
      //     type: "SET_MESSAGE",
      //     payload: { color: "success", display: true, text: data.message },
      //   });

      //   navigate("/home");
      // } catch (err) {
      //   setLoading(false);

      //   console.log(err);
      //   if (err && err.response) {
      //     if (err.response.status === 422) {
      //       setError({ ...err.response.data.error });
      //     }

      //     if (err.response.status === 400) {
      //       uiDispatch({
      //         type: "SET_MESSAGE",
      //         payload: {
      //           color: "error",
      //           display: true,
      //           text: err.response.data.error,
      //         },
      //       });
      //     }
      //   }
      // }
    }
  };

  const theme = createTheme(themeOptions);

  const formRef = createRef();

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          // width={"80%"}
          display="flex"
          alignItems="center"
          justifyContent="center"
          m="auto"
        >
          <Paper variant="elevation" elevation={2}>
            <Grid
              container
              direction="column"
              justify="center"
              spacing={2}
              padding={2}
            >
              <Grid item>
                <Typography variant="h5" gutterBottom>
                SeniorSynCare Settings
                </Typography>
              </Grid>

              <Grid item>
                <Form
                  formData={formData}
                  schema={schema}
                  uiSchema={ui_schema}
                  validator={validator}
                  onSubmit={formSubmit}
                  showErrorList="none"
                  ref={formRef}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Settings;
