import React, { useState, useContext, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";

import { RelationshipAvatar } from "../Relationships/RelationshipAvatar";
import { UserModal } from "./UserModal";

import { loadUsers } from "../Users/useUsers";

import { authBackend } from "../Backend";

import { UIContext, UserContext } from "../../App";

export default function DenseTable() {
  const { userState, userDispatch } = useContext(UserContext);
  const { uiDispatch } = useContext(UIContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalId, setModalId] = useState(0);
  const [modalEdit, setModalEdit] = useState(false);

  const handleDeleteRecord = (selectedId) => {
    console.log("DELETING RELATIONSHIP FOR ID", selectedId);

    const recipient_id =
      localStorage.recipient_id && JSON.parse(localStorage.recipient_id);

    authBackend({
      method: "delete",
      path: `user/${selectedId}`,
      payload: {
        recipient_id: recipient_id,
      },
    })
      .then((data) => {
        if (data.error !== undefined) {
          uiDispatch({
            type: "SET_MESSAGE",
            payload: {
              color: "error",
              display: true,
              text: data.error,
            },
          });
        } else {
          uiDispatch({
            type: "SET_MESSAGE",
            payload: { color: "success", display: true, text: data.message },
          });

          loadUsers().then((data) => {
            // setData({ ...data, result: data.result });

            userDispatch({
              type: "SET_USERS",
              payload: data.result,
            });
          });
          handleSubmitModal();
        }
      })
      .catch((err) => {
        console.log("formSubmit ERROR");
        console.log(err);
        if (err && err.message) {
          // if (err.response.status === 422) {
          //   setError({ ...err.response.message });
          // }

          // if (err.response.status === 420) {
          uiDispatch({
            type: "SET_MESSAGE",
            payload: {
              color: "error",
              display: true,
              text: err.message,
            },
          });
          // }
        }
      });

    // setModalEdit(true);
    // setModalId(selectedId);
    // setModalOpen(true);
  };

  const handleEditRecord = (selectedId) => {
    setModalEdit(true);
    setModalId(selectedId);
    setModalOpen(true);
  };

  const handleAddRecord = () => {
    setModalEdit(false);
    setModalOpen(true);
  };

  const handleOpenModal = () => {
    setModalEdit(false);
    setModalId(0);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    // loadUsers();
  };

  const handleSubmitModal = () => {
    setModalOpen(false);

    loadUsers().then((data) => {
      // setData({ ...data, result: data.result });

      userDispatch({
        type: "SET_USERS",
        payload: data.result,
      });
    });
    setModalOpen(false);
  };

  // const tableData = userState.users.map((item, index) => {
  //   return {
  //     first_name: item.first_name,
  //     last_name: item

  //     key: index + 100,
  //     title: item.first_name + " " + item.last_name,
  //     id: item.id,
  //     group: item.relationship.name,
  //   }

  const UserRelationshipAvatar = ({ relationship_id }) => {
    const my_relationship = userState.relationships.find(
      (x) => x.id == relationship_id
    );
    return (
      <>
        {my_relationship && (
          <RelationshipAvatar
            size={25}
            label={my_relationship.name}
            configString={my_relationship.icon}
          />
        )}
      </>
    );
  };

  const handleValidate = (formData) => {
    console.log("HANDLE VALIDATE ", formData);
    // if the number of POAs is 1 and this is the poa then do not allow the formdata to be anything but POA

    var poaList = [];
    var poa_relationship_id = 0;
    userState.users.forEach((user) => {
      if (user.relationship.name == "POA") {
        poaList.push(user.id);
        poa_relationship_id = user.relationship.id;
      }
    });
    if (
      poaList.includes(modalId) &&
      poaList.length === 1 &&
      formData.relationship_id !== poa_relationship_id
    ) {
      return {
        field: "relationship_id",
        message:
          "Can not change the relationship of the only POA for this site.",
      };
    }

    return;
  };

  return (
    <>
      <UserModal
        modalOpen={modalOpen}
        modalId={modalId}
        modalEdit={modalEdit}
        handleSubmit={handleSubmitModal}
        handleClose={handleCloseModal}
        onValidate={handleValidate}
      />
      {!modalOpen && (
        <Box
          // width={"100%"}
          // display="flex"
          alignItems="center"
          justifyContent="center"
        // m="auto"
        >
          <Paper variant="elevation" elevation={2}>
            <Grid
              container
              direction="column"
              justify="center"
              // spacing={2}
              padding={2}
            >
              <Grid item>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h5" gutterBottom>
                    Users
                  </Typography>
                  <Button variant="contained" onClick={handleAddRecord}>
                    Add
                  </Button>
                </Stack>
              </Grid>
              <Grid item>
                <TableContainer
                  style={{ maxHeight: 640 }}
                  sx={{ mt: 3.5 }}
                  component={Paper}
                >
                  {/* <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table"> */}
                  <Table stickyHeader size="small" aria-label="a dense table">
                    {/* <TableHead>
                      <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell width={50}>Actions</TableCell>
                      </TableRow>
                    </TableHead> */}
                    <TableBody>
                      {userState.users.map((row) => (
                        <TableRow
                          key={row.id}
                        // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell>
                            <Stack direction="row" spacing={2}>
                              <UserRelationshipAvatar
                                relationship_id={row.relationship.id}
                              />
                              <p> </p>
                              {row.first_name} {row.last_name}
                            </Stack>
                          </TableCell>
                          {/* <TableCell>{row.last_name}</TableCell> */}
                          <TableCell width={50} align="right">
                            <Stack direction="row">
                              <IconButton
                                aria-label="edit"
                                onClick={() => {
                                  handleEditRecord(row.id);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              {row.relationship.name !== "POA" &&
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => {
                                    handleDeleteRecord(row.id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              }
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
    </>
  );
}
