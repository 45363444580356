import { useState, useEffect } from "react";

import { parseISO, addDays } from "date-fns";
import { getInitialRepeat } from "./useRepeat";

import { confirmEvent, categories, deleteEvent } from "./useSchedule";

import { DateField, SelectField } from "./ScheduleFields";

import { RepeatFields } from "./RepeatFields";

import {
  TextField,
  Button,
  DialogActions,
  Grid,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TabPanel,
  Tab,
  Typography,
} from "@mui/material";

import SharingWidget from "../Logs/SharingWidget";

import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";

dayjs.extend(dayjsPluginUTC);

const EventWidget = ({ event, onClose, onConfirm, action }) => {
  const [error, setError] = useState("");

  const handleDelete = async () => {
    // console.log("Delete Event ", event);

    deleteEvent(event.event_id, event.db_id).then((deleted_event) => {
      onConfirm(deleted_event, "edit");
    });

    onClose();
  };

  // console.log("OPEN EVENT", event);

  const [state, setState] = useState({
    title: event?.title || "",
    repeat: event?.repeat || getInitialRepeat(event.start, event.end),
    description: event?.description || "",
    start: event.start,
    end: event.end,
    // start: event?.start,
    // end: event?.end,
    db_id: event?.db_id || undefined,
    duration: (event.end - event.start) / 1000,
    type: event?.type || 0,
    sharing:
      action === "edit"
        ? event.sharing
        : {
          share_everyone: false,
          share_relationships: ",,",
          share_users: ",,",
        },
  });

  const handleChange = (value, name) => {
    const newState = { ...state, [name]: value };

    if (name === "start") {
      const new_end = dayjs(value)
        .add(state.duration, 'seconds').toDate();
      newState.end = new_end;
    }
    if (name === "type" && categories[value].name === "Available") {
      newState.title = "Available";
    }

    newState.duration = (newState.end - newState.start) / 1000;
    setState(newState);
  };

  const handleSubmit = async () => {
    // Your own validation
    if (state.title.length < 2) {
      return setError("Min 2 letters");
    }

    try {
      // scheduler.loading(true);

      const added_updated_event = await confirmEvent(
        {
          event_id: event ? event.event_id : 1,
          db_id: event ? event.db_id : 1,
          start: state.start,
          end: state.end,
          title: state.title,
          description: state.description,
          duration: state.duration,
          repeat: state.repeat,
          type: state.type,
          sharing: state.sharing,
        },
        action
      );

      if (onConfirm) {
        onConfirm(added_updated_event, action);
      }
      onClose();
    } finally {
      // scheduler.loading(false);
    }
  };

  return (
    <>
      <Box sx={{ m: 2, maxWidth: "md" }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              label="Title"
              disabled={state.type === 3} // disabled with Available
              value={
                typeof state.title === "string"
                  ? state.title
                  : state.title.props?.children[2]
              }
              onChange={(e) => handleChange(e.target.value, "title")}
              error={!!error}
              helperText={error}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <SelectField
              label={"Type"}
              config={state}
              prop={"type"}
              onChange={handleChange}
              selectList={categories}
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              label={"Starting on"}
              config={state}
              prop={"start"}
              onChange={handleChange}
            // maxDate={state.end}
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              label={"And Ends"}
              config={state}
              prop={"end"}
              onChange={handleChange}
              minDate={state.start}
            />
          </Grid>
          <RepeatFields
            start={state.start}
            end={state.end}
            value={state.repeat}
            onChange={(x) => {
              handleChange(x, "repeat");
            }}
          />
          <Grid item xs={12}>
            <SharingWidget
              fullWidth
              label={"Send Email Updates To..."}
              value={state.sharing}
              // onChange={(sharing) => {
              //   console.log("SHARING UPDATE", sharing);
              // }}
              onChange={(sharing) => {
                handleChange(sharing, "sharing");
              }}
            // value={logSharing}
            // onChange={setLogSharing}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              value={state.description}
              onChange={(e) => handleChange(e.target.value, "description")}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>{" "}
          <Grid item xs={12}>
            <DialogActions>
              {action === "edit" && (
                <Button color="error" onClick={handleDelete}>
                  Delete
                </Button>
              )}
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={handleSubmit}>Confirm</Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EventWidget;
