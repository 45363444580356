// import React, { useState, useEffect, useContext } from "react";

// https://github.com/MertCankaya/Facebook-Clone/blob/main/src/Feed.js

import { publicBackend } from "../Backend";

export const getAllPages = async (setPages) => {
  // need to be able to unpack the subdomain

  var recipient_id;
  if (!localStorage.recipient_id) {
    const { result } = await publicBackend({
      path: "page/site/",
      filters: [{ col: "recipient", opr: "eq", value: "Stella" }],
    });
    recipient_id = result.recipient_id;
  } else {
    recipient_id = JSON.parse(localStorage.recipient_id);
  }
  // else use the test site

  return await publicBackend({
    path: "page/",
    filters: [{ col: "recipient_id", opr: "eq", value: recipient_id }],
  })
    .then((data) => {

      // console.log("GETPAGES RESULT")
      // console.log(data);
      setPages(data.result);
      return;
    })
    .catch((err) => {
      return err;
    });
};

export const getPage = async (page_id, setPage) => {
  const recipient_id = JSON.parse(localStorage.recipient_id);
  // console.log("GET PAGE", page_id);
  return await publicBackend({
    path: `page/${page_id}`,
    filters: [{ col: "recipient_id", opr: "eq", value: recipient_id }],
  })
    .then((data) => {
      // console.log("getPage");
      // console.log(data)

      setPage(data.result);
      return;
    })
    .catch((err) => {
      return err;
    });
};
