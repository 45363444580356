import React, { useContext, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import LoginForm from "./LoginForm";
import ProfileForm from "./ProfileForm";
import {
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

// import { UserContext } from "../../App";
import { LogoutUser } from "./AuthService";
import { UIContext, UserContext } from "../../App";

const Logout = () => {
  const navigate = useNavigate();
  const { uiDispatch } = useContext(UIContext);
  const { userDispatch } = useContext(UserContext);

  useEffect(() => {
    LogoutUser()
      .then((data) => {
        userDispatch({ type: "LOGOUT_USER" });
        navigate("/login");
      })
      .catch((err) => {
        uiDispatch({
          type: "SET_MESSAGE",
          payload: {
            color: "error",
            display: true,
            text: res.data.error,
          },
        });

        console.log("LOGOUT ERROR");
        console.log(err);
      });
  }, []);
};

export default Logout;
