import React, {
  useContext,
  useState,
  createRef,
  useEffect,
  Fragment,
} from "react";

// import axios from "axios";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  IconButton,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Link,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import Form from "@rjsf/mui";

import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv6";

import { fetchCurrentUser } from "../Auth/AuthService";
import { authBackend } from "../Backend";

import { PhoneNumberInput } from "../PhoneNumberInput";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import jwtDecode from "jwt-decode";
// import { withTheme } from '@rjsf/core';
// import Theme from '@rjsf/mui';
// Make modifications to the theme with your own fields and widgets
// const Form = withTheme(Theme);

import { UIContext, UserContext } from "../../App";
// const url =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   ":" +
//   process.env.REACT_APP_API_PORT;

const themeOptions = {
  typography: {
    fontFamily: "Quicksand,Mulish",
    allVariants: {
      color: "black"
    },
  },
  palette: {
    primary: {
      main: '#87479C',
      dark: '#4C2E87',
      // contrastText: '#242420'
    },
    secondary: {
      main: '#095296',
    },
  },
  components: {
    MuiButton: {
      // defaultProps: {
      //   size: "medium",
      // },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: "none",
          fontSize: "1rem",
          margin: "0px",
          // padding: "0px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: "0px",
          padding: "0px",
        },
      },
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
  },
};

export const UserModal = ({
  modalOpen = false,
  modalId = 0,
  modalEdit = false,
  handleClose,
  handleSubmit,
  onValidate,
}) => {
  const { uiDispatch } = useContext(UIContext);
  const [loading, setLoading] = useState(false);
  const { userState, userDispatch } = useContext(UserContext);
  const [validated, setValidated] = useState(false);

  const initialState = {
    first_name: "",
    last_name: "",
    mobile_phone: "",
    home_phone: "",
    username: "",
    email: "",
    new_password: "",
    confirm_password: "",
    language_id: 1,
    pronouns_id: 1,
  };

  const [currentData, setCurrentData] = useState(initialState);

  const schema = {
    // title: modalEdit ? "Edit User" : "Add User",
    // title: modalEdit ? "Edit User" : "Add User",
    // "description": "ThinkBeyond Login",
    type: "object",
    required: modalEdit === true ? [] : ["email", "first_name", "last_name"],
    properties: {
      recipient_id: {
        type: "integer",
        default: JSON.parse(localStorage.recipient_id),
      },
      email: {
        type: "string",
        format: "email",
        title: "Email",
        minLength: 3,
      },
      relationship_id: {
        type: "number",
        title: "Relationship",
        oneOf: userState.relationships.map((relationship) => {
          return { const: relationship.id, title: relationship.name };
        }),
        default: 2,
      },

      first_name: {
        type: "string",
        title: "First Name",
        minLength: 3,
      },
      last_name: {
        type: "string",
        title: "Last  Name",
        minLength: 3,
      },
      ...(modalEdit === true && {
        mobile_phone: {
          type: "string",
          title: "Mobile Phone",
          // minLength: 3,
        },
      }),
      ...(modalEdit === true && {
        home_phone: {
          type: "string",
          title: "Home Phone",
          // minLength: 3,
        },
      }),
      ...(modalEdit === true && {
        language_id: {
          type: "number",
          title: "Language",
          oneOf: [
            { const: 1, title: "English" },
            { const: 2, title: "French" },
          ],
        },
      }),

      ...(modalEdit === true && {
        pronouns_id: {
          readonly: true,
          type: "number",
          title: "Pronouns",
          oneOf: [
            { const: 1, title: "She/Her" },
            { const: 2, title: "He/Him" },
            { const: 3, title: "They/Them" },
          ],
        },
      }),

      //   password_reset: modalEdit
      //     ? {
      //         type: "boolean",
      //         title: "Send password reset email.",
      //         description: "This is the checkbox-description",
      //       }
      //     : {
      //         type: "null",
      //         description:
      //           "After pressing submit a registration email will be sent to the new user.",
      //       },
      //   // new_password: {
      //   //   type: "string",
      //   //   format: "password",
      //   //   title: "New Password",
      //   //   minLength: 3,
      //   // },
      //   // confirm_password: {
      //   //   type: "string",
      //   //   format: "password",
      //   //   title: "Confirm Password",
      //   //   minLength: 3,
      //   // },
    },
  };

  const token = localStorage.token && JSON.parse(localStorage.token);

  const ui_schema = {
    email: {
      "ui:autofocus": true,
      // "ui:description": "Login Username",
    },
    recipient_id: { "ui:widget": "hidden" },
    "ui:submitButtonOptions": {
      submitText: modalEdit ? "Update" : "Submit",
      norender: false,
      props: {
        disabled: false,
        className: "btn btn-info",
      },
    },
    email: {
      "ui:readonly": modalEdit === true,
    },
    first_name: {
      "ui:readonly": modalEdit === true,
    },
    last_name: {
      "ui:readonly": modalEdit === true,
    },
    home_phone: {
      "ui:readonly": true,
    },
    mobile_phone: {
      "ui:readonly": true,
    },
    language_id: {
      "ui:readonly": true,
    },
    pronouns_id: {
      "ui:readonly": true,
    },
  };

  const getUser = async (user_id) => {
    const token = "";

    await authBackend({
      path: `user/${user_id}`,
    })
      .then((data) => {
        console.log("TABLE GET USER!");
        return data.result;
      })
      .catch((err) => {
        if (err && err.response) {
          return {
            error: err.response.data.error,
          };
        }
      });

    // try {
    //   const { data } = await axios.get(`${url}/api/v1/user/${user_id}`, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });
    //   if (data) {
    //     console.log("TABLE GET USER!");
    //     return data.result;
    //     // return {
    //     //   data['result']
    //     // };
    //   }
    // } catch (err) {
    //   if (err && err.response) {
    //     return {
    //       error: err.response.data.error,
    //     };
    //   }
    // }
  };

  async function loadCurrentUser() {
    if (modalEdit && token) {
      // console.log("Modal id");
      // console.log(modalId);
      // const currentUser = await getUser(modalId);

      // const recipient_id = JSON.parse(localStorage.recipient_id);
      // console.log("Current User", currentUser);
      // setFormData(currentUser);
      const recipient_id = JSON.parse(localStorage.recipient_id);

      await authBackend({
        path: `user/${modalId}`,
        filters: [{ col: "recipient_id", opr: "eq", value: recipient_id }],
      })
        .then((data) => {
          setCurrentData(data.result);
          return data;
        })
        .catch((err) => {
          return err;
        });

      // setFormData({
      //   email: currentUser.data.user.username,
      //   first_name: currentUser.data.user.first_name,
      //   last_name: currentUser.data.user.last_name,
      // });
    }
  }

  useEffect(() => {
    // console.log("Use Effect load current user");

    if (modalEdit) {
      loadCurrentUser();
    } else {
      setCurrentData(initialState);
    }
  }, [modalOpen]);

  // loadCurrentUser();

  const handleChange = ({ formData }, e) => {
    setCurrentData({ ...currentData, ...formData });
    // console.log(formRef);
    // if (formRef.current.validateForm()) {
    //   setValidated(true);
    // } else {
    //   setValidated(false);
    // }
  };

  const formSubmit = async () => {
    if (formRef.current.validateForm()) {
      setLoading(true);

      const recipient_id =
        localStorage.recipient_id && JSON.parse(localStorage.recipient_id);

      var submitMethod;
      var submitPath;
      var submitData = {
        email: currentData.email,
        relationship_id: currentData.relationship_id,
        recipient_id: recipient_id,
      };
      if (modalEdit) {
        submitMethod = "put";
        submitPath = `user/${modalId}`;
      } else {
        submitMethod = "post";
        submitData.first_name = currentData.first_name;
        submitData.last_name = currentData.last_name;
        submitPath = "user/";
      }

      console.log("SUBMIT DATA", submitData);

      await authBackend({
        method: submitMethod,
        path: submitPath,
        payload: submitData,
      })
        .then((data) => {
          // console.log("formSubmit");
          // console.log(data);
          setLoading(false);

          if (data.error !== undefined) {
            uiDispatch({
              type: "SET_MESSAGE",
              payload: {
                color: "error",
                display: true,
                text: data.error,
              },
            });
            handleSubmit();
          } else {
            uiDispatch({
              type: "SET_MESSAGE",
              payload: { color: "success", display: true, text: "Success!" },
            });
            handleSubmit(data.result);
          }
        })
        .catch((err) => {
          setLoading(false);

          console.log("formSubmit ERROR");
          console.log(err);
          if (err && err.message) {
            // if (err.response.status === 422) {
            //   setError({ ...err.response.message });
            // }

            // if (err.response.status === 420) {
            uiDispatch({
              type: "SET_MESSAGE",
              payload: {
                color: "error",
                display: true,
                text: err.message,
              },
            });
            // }
          }
        });
    }
  };

  function customValidate(formData, errors, uiSchema) {
    if (onValidate != undefined) {
      const customError = onValidate(formData);
      if (customError) {
        const field = customError.field;
        errors[field].addError(customError.message);
      }
    }
    return errors;
  }

  const formRef = createRef();
  const theme = createTheme(themeOptions);
  return (
    <>
      <ThemeProvider theme={theme}>
        {/* <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      > */}
        {modalOpen && (
          <Box
            width={"100%"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            m="auto"
          >
            <Paper variant="elevation" elevation={2}>
              <Grid
                container
                direction="column"
                justify="center"
                spacing={2}
                padding={2}
              >
                <Grid item>
                  <Box
                    sx={{ display: "flex", flexDirection: "row" }}
                    // sx={{ display: "flex", flexDirection: "row", pt: 2, pl: 2 }}
                  >
                    <Typography variant="h5" gutterBottom>
                      {modalEdit ? "Edit User" : "Add User"}
                    </Typography>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <IconButton
                      color="primary"
                      aria-label="add to shopping cart"
                      onClick={handleClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Grid>

                <Grid item>
                  <Form
                    formData={currentData}
                    schema={schema}
                    uiSchema={ui_schema}
                    validator={validator}
                    onChange={handleChange}
                    customValidate={customValidate}
                    widgets={{
                      PhoneNumberInput: PhoneNumberInput,
                    }}
                    showErrorList="none"
                    ref={formRef}
                    // ref={formRef}

                    // onSubmit={formSubmit}
                  >
                    <Fragment />
                  </Form>
                </Grid>
                <Grid item></Grid>
                <Box
                  sx={{ display: "flex", flexDirection: "row", pt: 2, pl: 2 }}
                >
                  <Button
                    onClick={handleClose}
                    sx={{ margin: 1 }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                    onClick={formSubmit}
                    // disabled={!validated}
                    sx={{ margin: 1 }}
                    variant="contained"
                  >
                    {modalEdit ? "Update" : "Submit"}
                  </Button>
                </Box>
              </Grid>
            </Paper>
          </Box>
        )}
        {/* </Modal> */}
      </ThemeProvider>
    </>
  );
};
