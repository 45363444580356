export const initialUserState = {
  isLoggedIn: false,
  currentUser: null,
  users: [],
  relationships: [],
  socketio: null,
  selectedUserProfile: null,
  selectedRecipient: null,
};

export const UserReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_PROFILE":
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          [action.payload.label]: action.payload.value,
        },
      };

    case "SET_CURRENT_USER":
      return {
        ...state,
        currentUser: action.payload,
        isLoggedIn: true,
      };

    case "SET_CURRENT_RECIPIENT":
      return {
        ...state,
        selectedRecipient: action.payload,
      };

    case "SET_USERS":
      return {
        ...state,
        users: action.payload,
      };

    case "UPDATE_USER":
      let i_01 = state.users.findIndex(
        (user) => parseInt(user.id) === parseInt(action.payload.id)
      );
      if (i_01 !== -1) {
        state.users[i_01] = action.payload;
      }
      return {
        ...state,
        currentUser: action.payload,
      };

    case "SET_RELATIONSHIPS":
      return {
        ...state,
        relationships: action.payload,
      };

    case "REMOVE_USER":
      let f_users = state.users.filter(
        (user) => parseInt(user.id) !== parseInt(action.payload.id)
      );
      return {
        ...state,
        users: f_users,
      };

    case "LOGOUT_USER":
      if (localStorage.token) {
        localStorage.removeItem("token");
        // localStorage.removeItem("recipient_id");
      }
      return {
        ...state,
        currentUser: null,
        isLoggedIn: false,
        users: [],
        selectedUserProfile: null,
        selectedRecipient: action.payload,
      };

    case "ADD_SELECTED_USER_PROFILE":
      return {
        ...state,
        selectedUserProfile: action.payload,
      };

    case "REMOVE_SELECTED_USER_PROFILE":
      return {
        ...state,
        selectedUserProfile: null,
      };

    case "SET_SOCKETIO":
      return {
        ...state,
        socketio: action.payload,
      };

    default:
      throw new Error(`action type ${action.type} is undefined`);
  }
};
