import React, {
  lazy,
  Suspense,
  createContext,
  useReducer,
  useEffect,
  useContext,
  useState,
  useMemo
} from "react";

import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import { Snackbar, Alert } from "@mui/material";

import jwtDecode from "jwt-decode";

import "./App.css";

import { initialUIState, UIReducer } from "./common/UIContext";
import { UserReducer, initialUserState } from "./common/Auth/UserContext";

import { getAllPages } from "./common/Pages/Pages";
import { Page } from "./common/Pages/Page";

import Home from "./common/Pages/Home";

// import Auth from "./common/User/Auth";
// import Logout from "./common/User/Logout";

import Login from "./common/Auth/Login";
import AutoLogin from "./common/Auth/AutoLogin";
import Profile from "./common/Auth/Profile";
import Settings from "./common/Site/Settings";
import Register from "./common/Registration/Register";
import SetPassword from "./common/Registration/SetPassword";
import PagesSetup from "./common/Pages/Setup";
import PasswordReset from "./common/Auth/PasswordReset";
import AccountSettings from "./common/Account/AccountSettings";
import Logout from "./common/Auth/Logout";
import { fetchCurrentUser } from "./common/Auth/AuthService";

import { publicBackend } from "./common/Backend";

import UsersTable from "./common/Users/UsersTable";
import RelationshipsTable from "./common/Relationships/RelationshipsTable";

import { loadRelationships } from "./common/Relationships/useRelationships";
import { loadUsers } from "./common/Users/useUsers";

import Logs from "./common/Logs/Logs";
// import Builder from "./common/Builder/WebBuilder";
import Schedule from "./common/Calendar/Schedule";
import Calendar from "./common/Calendar/Calendar";

import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import Box from "@mui/material/Box";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Cottage";
import PersonIcon from "@mui/icons-material/Person";
// import BuildIcon from "@mui/icons-material/Build";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import EventIcon from "@mui/icons-material/Event";
import GroupsIcon from "@mui/icons-material/Groups";
import ElderlyIcon from "@mui/icons-material/Elderly";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AccessibilityIcon from '@mui/icons-material/Accessibility';

import { v4 as uuidV4 } from "uuid";

import "typeface-quicksand";
import "typeface-mulish";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";


export const UIContext = createContext();
export const UserContext = createContext();

export const ProtectedRoute = () => {
  const { userState } = useContext(UserContext);
  return userState.isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

const drawerWidth = 240;

const Loader = lazy(() => import("./common/Loader"));
// const Navbar = lazy(() => import("./common/Navbar"));
// const Auth = lazy(() => import("./common/User/Auth"));
// const Logs = lazy(() => import("./common/Logs"));
// const Builder = lazy(() => import("./common/Builder/WebBuilder"));

// const Navbar = lazy(() => import("./common/Navbar"));
// const Loader = lazy(() => import("./common/Loader"));
// const Auth = lazy(() => import("./common/User/Auth"));
// const Logs = lazy(() => import("./common/Logs"));
// const Builder = lazy(() => import("./common/Builder/WebBuilder"));

const App = () => {
  const [uiState, uiDispatch] = useReducer(UIReducer, initialUIState);
  const [userState, userDispatch] = useReducer(UserReducer, initialUserState);

  const [pages, setPages] = useState([]);
  const [recipientId, setRecipientId] = useState(0);
  const [recipient, setRecipient] = useState({ id: 0, nickname: "" });
  const [themeFontSize, setThemeFontSize] = useState(16);

  const theme = useMemo(
    () => createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
      shape: {
        borderRadius: 10,    // defaults to 4
      },
      typography: {
        fontFamily: "Quicksand,Mulish",
        fontSize: themeFontSize,
        allVariants: {
          color: "black"
        },
      },
      palette: {
        primary: {
          main: '#87479C',
          dark: '#4C2E87',
          // contrastText: '#242420'
        },
        secondary: {
          main: '#095296',
        },
      },
      components: {
        MuiLink: {
          styleOverrides: {
            root: {
              textDecoration: "none",
              ":hover": {
                color: "#87479C",
                // textDecoration: "underline",
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
    }),
    [themeFontSize]
  );

  useEffect(() => {
    document.title = "SeniorSynCare";

    async function loadCurrentUser() {
      //       function splitHostname() {
      //         var result = {};
      //         var regexParse = new RegExp('([a-z\-0-9]{2,63})\.([a-z\.]{2,5})$');
      //         var urlParts = regexParse.exec(window.location.hostname);
      //         result.domain = urlParts[1];
      //         result.type = urlParts[2];
      //         result.subdomain = window.location.hostname.replace(result.domain + '.' + result.type, '').slice(0, -1);;
      //         return result;
      // }

      // TODO: Need to change this behavior so that if we are on a subdomain, then load a new recipient_id
      //   if the recipient_id is loaded and does not match the subdomain, then..
      //   if we are on a local development server, then...
      //   if we are not on a subdomain, then navigate to https://waitingforgod.ca, regardless of siite

      console.log("VERSION 2");
      // console.log("WINDOW LOCATION IS");
      // console.log(window.location.hostname);
      // console.log("PROTOCOL IS ", window.location.protocol);
      const hostname = window.location.hostname;
      // console.log("HOSTNAME", hostname);
      // console.log(hostname.split("."));

      const hostpath = window.location.hostname.split(".");
      const subdomain = hostpath.length === 1 ? "stella" : hostpath[0];

      console.log("SUBDOMAIN IS", subdomain);

      if (subdomain === 'stella'){
        setThemeFontSize(18);
      }

      // if subdomain is portal immediately go to portal login page

      const { result } = await publicBackend({
        path: "page/site/",
        filters: [{ col: "recipient", opr: "eq", value: subdomain }],
      });

      console.log("GETTING RECIPIENT ID");
      console.log(result);

      // console.log("Setting recipient_id to ", result.recipient_id);

      localStorage.setItem("recipient_id", result.recipient_id);

      if ((parseInt(result.recipient_id) == 0) && subdomain !== "portal") {
        console.log("redirect!");
        // window.location.assign("https://portal.waitingforgod.ca/login");
        window.location.assign("https://portal.seniorsyncare.com/login");
      }

      setRecipientId(result.recipient_id);
      setRecipient(result);

      if (localStorage.token) {
        const token = JSON.parse(localStorage.token);
        const decodeToken = jwtDecode(token);

        if (decodeToken.exp * 1000 < Date.now()) {
          userDispatch({ type: "LOGOUT_USER" });
        } else {
          const currentUser = await fetchCurrentUser();
          if (currentUser && currentUser.data) {
            userDispatch({
              type: "SET_CURRENT_USER",
              payload: currentUser.data.user,
            });
            userDispatch({
              type: "SET_CURRENT_RECIPIENT",
              payload: currentUser.data.user.recipients[0],
            });
          }
        }
      }

      loadRelationships().then((data) => {
        userDispatch({
          type: "SET_RELATIONSHIPS",
          payload: data.result,
        });
      });

      loadUsers().then((data) => {
        userDispatch({
          type: "SET_USERS",
          payload: data.result,
        });
      });

    }

    loadCurrentUser();


    // getAllPages(setPages);
  }, []);

  const loginMenuItems = [
    {
      name: "Home",
      path: "/home",
      icon: <HomeIcon color='secondary' />,
      allowed: true,
    },
    // { name: "Builder", path: "/build", icon: <BuildIcon /> },
    {
      name: "Discussions",
      path: "/discussions",
      icon: <ReceiptLongIcon color='secondary' />,
      allowed:
        userState.currentUser &&
        userState.currentUser.relationship.access_logs > 0,

      // userState.currentUser && userState.relationships
      //     ? userState.relationships.find(
      //         (x) => x.id == userState.currentUser.relationship.id
      //       ).access_logs > 0
      //     : false,
    },
    {
      name: "Schedule",
      path: "/schedule",
      icon: <EventIcon color='secondary' />,
      allowed:
        userState.currentUser &&
        userState.currentUser.relationship.access_schedule > 0,

      // userState.currentUser && userState.relationships
      //   ? userState.relationships.find(
      //       (x) => x.id == userState.currentUser.relationship.id
      //     ).access_schedule > 0
      //   : false,
    },
    // {
    //   name: "Users",
    //   path: "/users",
    //   icon: <GroupsIcon />,
    //   allowed: userState.currentUser
    //     ? userState.currentUser.relationship.name == "POA"
    //     : false,
    // },
    // {
    //   name: "Relationships",
    //   path: "/relationships",
    //   icon: <GroupsIcon />,
    //   allowed: userState.currentUser
    //     ? userState.currentUser.relationship.name == "POA"
    //     : false,
    // },
    // // {
    // //   name: "Pages",
    // //   path: "/pages",
    // //   icon: <SummarizeIcon />,
    // //   permissions: [1],
    // // },
    // {
    //   name: "My Profile",
    //   path: "/profile",
    //   icon: <PersonIcon />,
    //   allowed: true,
    // },
    // {
    //   name: "My Recipient",
    //   path: "/settings",
    //   icon: <ElderlyIcon />,
    //   allowed: userState.currentUser
    //     ? userState.currentUser.relationship.name == "POA"
    //     : false,
    // },
    {
      name: "My Account",
      path: "/myaccount",
      icon: <AccessibilityIcon color='secondary' />,
      allowed: true,
      // allowed: userState.currentUser
      //   ? userState.currentUser.relationship.name == "POA"
      //   : false,
    },
    {
      name: "Logout",
      path: "/logout",
      icon: <LogoutIcon color='secondary' />,
      allowed: true,
    },
  ];

  // const theme = createTheme(themeOptions);

  return (
    <>
      <ThemeProvider theme={theme}>
        <UIContext.Provider value={{ uiState, uiDispatch }}>
          <UserContext.Provider value={{ userState, userDispatch }}>
            {recipientId !== 0 && userState.isLoggedIn && (
              <Navbar menuItems={loginMenuItems} />
            )}
            <Box sx={{ display: "flex" }}>
              {recipientId !== 0 && userState.isLoggedIn && (
                <Sidebar menuItems={loginMenuItems} />
              )}
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  p: 3,
                  width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
              >
                <Suspense fallback={<Loader />}>
                  <Routes>
                    {/* {recipientId === 0 && (
                    <>
                      <Route index element={<Navigate to="/login" replace />} />
                    </>
                  )} */}

                    {/* {recipientId !== 0 && ( */}
                    {/* <> */}
                    {/* <Route
                        path="/"
                        element={<Navigate to="/home" replace />}
                      /> */}
                    {/* <Route path="/" element={<Home recipient={recipient} />}> */}
                    <Route
                      path="/"
                      element={<Navigate replace={true} to="/home" />}
                    >
                      <Route
                        path="*"
                        element={<Navigate replace={true} to="/home" />}
                      />
                    </Route>

                    <Route
                      path="/home"
                      element={<Home recipient={recipient} />}
                    />
                    {/* <Route index element={<Home recipient={recipient} />} /> */}

                    {/* {pages.map((page, index) => (
                        <Route
                          key={uuidV4()}
                          // key={page.id}
                          path={page.page_template.title}
                          element={
                            <Page
                              pageinfo={{ index: index, pages: pages }}
                              index={index}
                            />
                          }
                        />
                      ))} */}
                    <Route
                      path="calendar"
                      // element={
                      //   <>
                      //     {" "}
                      //     <Page pageinfo={{ pages: pages }} /> <Calendar />
                      //   </>
                      // }
                      element={<Calendar recipient={recipient} />}
                    />
                    {/* </>
                  )} */}

                    {/* <Route path=""> */}
                    <Route path="login">
                      <Route path="" element={<Login />} />
                      <Route path=":loginToken" element={<AutoLogin />} />
                    </Route>
                    <Route path="reset" element={<PasswordReset />} />
                    <Route path="register" element={<Register />} />
                    <Route path="setpassword">
                      <Route path=":identifier" element={<SetPassword />} />
                    </Route>

                    {recipientId !== 0 && (
                      <>
                        <Route element={<ProtectedRoute />}>
                          {/* <Route path="build" element={<Builder />} /> */}
                          <Route
                            path="discussions"
                            element={<Logs key={uuidV4()} />}
                          />
                          <Route
                            path="schedule"
                            element={<Schedule key={uuidV4()} />}
                          />
                          <Route
                            path="users"
                            element={<UsersTable key={uuidV4()} />}
                          />
                          <Route
                            path="relationships"
                            element={<RelationshipsTable key={uuidV4()} />}
                          />
                          <Route
                            path="profile"
                            element={<Profile key={uuidV4()} />}
                          />
                          <Route
                            path="pages"
                            element={<PagesSetup key={uuidV4()} />}
                          />
                          <Route
                            path="settings"
                            element={<Settings key={uuidV4()} />}
                          />
                          <Route
                            path="myaccount"
                            element={<AccountSettings key={uuidV4()} themeFontSize={themeFontSize} onFontSizeChange={setThemeFontSize} />}
                          />
                          <Route path="logout" element={<Logout />} />
                        </Route>
                      </>
                    )}
                    {/* </Route> */}
                  </Routes>
                </Suspense>
                {uiState.message && (
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={uiState.message.display}
                    autoHideDuration={6000}
                    onClose={() =>
                      uiDispatch({ type: "SET_MESSAGE", payload: null })
                    }
                    style={{ color: "#fff", marginTop: 60 }}
                  >
                    <Alert
                      onClose={() =>
                        uiDispatch({ type: "SET_MESSAGE", payload: null })
                      }
                      severity={uiState.message.color}
                    >
                      {uiState.message.text}
                    </Alert>
                  </Snackbar>
                )}
              </Box>
            </Box>
          </UserContext.Provider>
        </UIContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default App;
