import {
  TextField,
  Button,
  DialogActions,
  Grid,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TabPanel,
  Tabs,
  Tab,
  Typography,
  Stack,
  ButtonGroup,
  InputAdornment,
} from "@mui/material";

import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import { datetime, RRule, RRuleSet, rrulestr } from "rrule";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

import _ from "lodash";

import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";

export const SelectField = ({ config, label, prop, selectList, onChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={config[prop]}
        label={label}
        onChange={(e) => onChange(e.target.value, prop)}
        fullWidth
      >
        {selectList.map((item, index) => (
          <MenuItem key={index} value={index}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const RangeField = ({ config, label, prop, size, onChange }) => {
  return (
    <FormControl style={{ minWidth: 200 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={config[prop]}
        label={label}
        onChange={(e) => onChange(e.target.value, prop)}
        fullWidth
      >
        {_.range(size).map((num) => (
          <MenuItem key={num} value={num + 1}>
            {num + 1}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const WeekdayField = ({ config, label, prop, selectList, onChange }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& > *": {
          m: 1,
        },
      }}
    >
      <ButtonGroup aria-label="outlined button group">
        {selectList.map((item, index) => (
          <Button
            key={index}
            variant={config[prop][index] ? "contained" : "outlined"}
            onClick={(e) => onChange(index, prop)}
          >
            {item.short}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export const DateField = ({
  config,
  label,
  prop,
  onChange,
  minDate,
  maxDate,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl fullWidth>
        <DateTimePicker
          value={config[prop]}
          label={label}
          ampmInClock={true}
          onChange={(e) => onChange(e, prop)}
          minutesStep={5}
          minDate={minDate}
          // maxDate={maxDate}
          minTime={minDate}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: null,
          }}
          // renderInput={(params) => (
          //   <TextField align="center" fullWidth {...params} />
          // )}
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export const IntervalField = ({
  config,
  label,
  prop,
  selectList,
  onChange,
}) => {
  return (
    <FormControl>
      <TextField
        value={config[prop]}
        label={label}
        onChange={(e) => onChange(e.target.value, [prop])}
        type="number"
        // error={!!error}
        // helperText={error}
        InputProps={{
          sx: {
            "& input": {
              textAlign: "center",
            },
          },
          endAdornment: (
            <InputAdornment position="end">
              <Typography>
                {selectList[config.freq].short + (config[prop] > 1 ? "s" : "")}
              </Typography>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};
