import { useState, useContext } from "react";
import axios from "axios";

import { UserContext, UIContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { fetchCurrentUser } from "./AuthService";

const url = window.location.origin === "http://localhost:3000" ? "http://localhost:8000" : window.location.origin;

const useProfileUser = () => {
  const navigate = useNavigate();

  const { uiDispatch } = useContext(UIContext);
  const { userState, userDispatch } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [initialState, setInitialState] = useState({
    name: userState.currentUser?.name,
    email: userState.currentUser?.email,
    password: "",
  });

  const handleNameChange = (e) => {
    setError({ ...error, name: userState.currentUser?.name });
    setInitialState({ ...initialState, name: e.target.value });
  };

  const handleEmailChange = (e) => {
    setError({ ...error, email: userState.currentUser?.name });
    setInitialState({ ...initialState, email: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setInitialState({ ...initialState, password: e.target.value });
    setError({ ...error, password: "" });
  };

  async function handleSignupUser(e) {
    e.preventDefault();

    setLoading(true);
    try {
      let token = userState.isLoggedIn ? JSON.parse(localStorage.token) : "";

      const authtype = userState.isLoggedIn ? "update" : "signup";
      const headers = userState.isLoggedIn
        ? { headers: { Authorization: `Bearer ${token}` } }
        : {};

      const { data } = await axios.post(
        `${url}/api/auth/${authtype}`,
        initialState,
        headers
      );
      localStorage.setItem("token", JSON.stringify(data.data.token));
      const me = await fetchCurrentUser();
      setLoading(false);

      userDispatch({ type: "SET_CURRENT_USER", payload: me.data.user });

      uiDispatch({
        type: "SET_MESSAGE",
        payload: { color: "success", display: true, text: data.message },
      });

      navigate("/home");
    } catch (err) {
      setLoading(false);

      console.log(err);
      if (err && err.response) {
        if (err.response.status === 422) {
          setError({ ...err.response.data.error });
        }

        if (err.response.status === 400) {
          uiDispatch({
            type: "SET_MESSAGE",
            payload: {
              color: "error",
              display: true,
              text: err.response.data.error,
            },
          });
        }
      }
    }
  }

  return {
    loading,
    error,
    handleSignupUser,
    handleNameChange,
    handleEmailChange,
    handlePasswordChange,
  };
};

export default useProfileUser;
