import { authBackend } from "../Backend";

export async function loadRelationships() {
  // if (token) {
  // const currentUser = await fetchCurrentUser();

  // const querystring = rison.encode({ columns: qcolumns });
  // console.log("Querystring", querystring);

  // http://localhost:5000/api/v1/example/private

  //   const querystring = rison({columns: columns});
  //   console.log("Querystring", querystring);
  const recipient_id = JSON.parse(localStorage.recipient_id);

  return authBackend({
    path: "relationship_type/",
    filters: [{ col: "recipient_id", opr: "eq", value: recipient_id }],
  })
    .then((data) => {
      return {
        ...data,
        result: data.result.sort(function (first, second) {
          return first.id - second.id;
        }),
      };
    })
    .catch((err) => {
      return err;
    });
}
