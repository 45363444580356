import { datetime, RRule, RRuleSet, rrulestr } from "rrule";

import _ from "lodash";

import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";

dayjs.extend(dayjsPluginUTC);

export const maxDate = () => {
  return datetime(9999, 12, 31);
};

export const calcUntil = (dtstart, freq, interval, count) => {
  // console.log(
  //   "CALC UNTIL",
  //   dtstart,
  //   freq,
  //   interval,
  //   count,
  //   frequencies[freq].unit
  // );

  const until = dayjs
    .utc(dtstart)
    .add(interval * count, frequencies[freq].unit)
    .toDate();
  // console.log("RESULT", until);
  return until;
};

export const getInitialRepeat = (start, end) => {
  return {
    dtstart: start,

    criterion: 1,
    count: 1,
    until: end,

    freq: 0,
    interval: 1,

    bymonth: 0,
    bymonthday: 1,
    bysetpos: 1,
    weekday: 1,
    weekdays: [true, false, false, false, false, false, false],

    rruleSet: new RRuleSet(),
    ics: "",
  };
};

const indexByValue = (list, lookup) => {
  return list.findIndex(({ value }) => value === lookup);
};

export const parseICS = (ics) => {
  // console.log("PARSE ICS TEXT", ics);

  const rruleSet = rrulestr(ics, {
    forceset: true,
    cache: false,
    compatible: true
  });

  // console.log("PARSE ICS", rruleSet);

  const rrule = rruleSet.rrules()[0];
  const options = rrule.options;
  // console.log("RULE OPTIONS", options);
  var repeat = {
    dtstart: options.dtstart,

    criterion: 1,
    count: 1,
    until: maxDate(),

    freq: 0,
    interval: 1,

    bymonth: 0,
    bymonthday: 1,
    bysetpos: 1,
    weekday: 1,
    weekdays: [true, false, false, false, false, false, false],

    rruleSet: rruleSet,
    ics: ics,
  };

  //   case 1: // "Yearly On"
  //   freqConfig = {
  //     freq: RRule.YEARLY,
  //     dtstart: dayjs.utc(repeat.dtstart).toDate(),
  //     bymonth: repeat.bymonth + 1,
  //     bymonthday: repeat.bymonthday,
  //   };

  // if (options.freq == RRule.YEARLY && options.bymonthday != null) {
  //   repeat.freq = 1;
  //   repeat.bymonth = options.bymonth - 1;
  //   repeat.bymonthday = options.bymonthday;
  // }

  // case 2: // "Yearly On The"
  //   freqConfig = {
  //     freq: RRule.YEARLY,
  //     dtstart: dayjs.utc(repeat.dtstart).toDate(),
  //     bymonth: repeat.bymonth + 1,
  //     bysetpos: [weekOfMonth[repeat.bysetpos].value],
  //     byweekday: weekdays[repeat.weekday].value,
  //   };

  // else if (options.freq == RRule.YEARLY && options.bymonthday != null) {
  //   repeat.freq = 2;
  //   repeat.bymonth = options.bymonth - 1;
  //   repeat.bysetpos = indexByValue(weekOfMonth, options.bysetpos);
  //   repeat.byweekday = indexByValue(weekdays, options.byweekday);
  // }

  // case 3: // "Monthly On"
  //   freqConfig = {
  //     freq: RRule.MONTHLY,
  //     dtstart: dayjs.utc(repeat.dtstart).toDate(),
  //     bymonthday: repeat.bymonthday,
  //     interval: repeat.interval,
  //   };
  if (options.freq == RRule.MONTHLY && options.bymonthday != null) {
    repeat.freq = 1;
    repeat.bymonthday = options.bymonthday;
    repeat.interval = options.interval;
  }
  // case 4: // "Monthly On The"
  //   freqConfig = {
  //     freq: RRule.MONTHLY,
  //     dtstart: dayjs.utc(repeat.dtstart).toDate(),
  //     bysetpos: [weekOfMonth[repeat.bysetpos].value],
  //     byweekday: weekdays[repeat.weekday].value,
  //   };
  else if (options.freq == RRule.MONTHLY && options.bymonthday != null) {
    repeat.freq = 2;
    repeat.bysetpos = indexByValue(weekOfMonth, options.bysetpos);
    repeat.byweekday = indexByValue(weekdays, options.byweekday);
  }
  // case 5: // "Weekly"
  //   freqConfig = {
  //     freq: RRule.WEEKLY,
  //     dtstart: dayjs.utc(repeat.dtstart).toDate(),
  //     byweekday: repeat.weekdays
  //       .map((item, index) => {
  //         return item ? weekdays[index].value : item;
  //       })
  //       .filter((item) => item),
  //     interval: repeat.interval,
  //   };
  else if (options.freq == RRule.WEEKLY) {
    repeat.freq = 3;
    repeat.interval = options.interval;

    repeat.weekdays = [false, false, false, false, false, false, false];
    weekdays.forEach((weekday, index) => {
      // if (options.byweekday.includes(weekday.value)) {
      if (options.byweekday.includes(index)) {
        repeat.weekdays[index] = true;
      }
    });
  }
  // case 6: // "Daily"
  //   freqConfig = {
  //     freq: RRule.DAILY,
  //     interval: repeat.interval,
  //     dtstart: dayjs.utc(repeat.dtstart).toDate(),
  //   };
  else if (options.freq == RRule.DAILY) {
    repeat.freq = 4;
    repeat.interval = options.interval;
  }
  // case 7: // "Hourly"
  //   freqConfig = {
  //     freq: RRule.HOURLY,
  //     interval: repeat.interval,
  //     dtstart: dayjs.utc(repeat.dtstart).toDate(),
  //   };
  // else if (options.freq == RRule.HOURLY) {
  //   repeat.freq = 7;
  //   repeat.interval = options.interval;
  // }

  if (options.count != null) {
    // end after
    repeat.count = options.count;
    repeat.criterion = 1;
    repeat.until = calcUntil(
      repeat.dtstart,
      repeat.freq,
      repeat.interval,
      repeat.count
    );
  } else if (options.until != null) {
    repeat.until = options.until;
    repeat.criterion = 0;
  } else {
    repeat.criterion = 2;
    repeat.until = calcUntil(
      repeat.dtstart,
      repeat.freq,
      repeat.interval,
      repeat.count
    );
  }

  // console.log("GENERATED", repeat);

  return repeat;
};

export const frequencies = [
  { name: "Never", short: "", unit: "" },
  // { name: "Yearly On", short: "", unit: "year" },
  // { name: "Yearly On The", short: "", unit: "year" },
  { name: "Monthly On", short: "Month", unit: "month" },
  { name: "Monthly On The", short: "Month", unit: "month" },
  { name: "Weekly", short: "Week", unit: "week" },
  { name: "Daily", short: "Day", unit: "day" },
  // { name: "Hourly", short: "Hour", unit: "hour" },
];

export const endsCriteria = [
  { name: "On date" },
  { name: "After" },
  { name: "Never" },
];

export const getMonths = () => {
  return _.range(12).map((num) => {
    const date = new Date();
    date.setMonth(num);

    return {
      name: date.toLocaleString("en-US", { month: "long" }),
    };
  });
};

export const weekdays = [
  { name: "Monday", short: "Mon", value: RRule.MO },
  { name: "Tueday", short: "Tue", value: RRule.TU },
  { name: "Wednesday", short: "Wed", value: RRule.WE },
  { name: "Thursday", short: "Thu", value: RRule.TH },
  { name: "Friday", short: "Fri", value: RRule.FR },
  { name: "Saturday", short: "Sat", value: RRule.SA },
  { name: "Sunday", short: "Sun", value: RRule.SU },
];

export const weekOfMonth = [
  { name: "First", value: 1 },
  { name: "Second", value: 2 },
  { name: "Third", value: 3 },
  { name: "Fourth", value: 4 },
  { name: "Last", value: -1 },
];

export const generateRRule = (repeat) => {
  var endConfig = {};
  switch (repeat.criterion) {
    case 0: // "Yearly On"
      endConfig = { until: dayjs.utc(repeat.until).toDate() };
      break;
    case 1: // "Yearly On"
      endConfig = { count: repeat.count };
      break;
    case 2:
      break;
  }

  const apply_fix = true;
  var dtstart = new Date(repeat.dtstart);
  if (apply_fix === true){
    var today = new Date();
    const today_offset = today.getTimezoneOffset();
    const gmt_offset = repeat.dtstart.getTimezoneOffset();
    const tz_offset = (today_offset - gmt_offset) / 60
    dtstart.setHours(dtstart.getHours() + tz_offset)
  }

  // dayjs.utc(repeat.dtstart).toDate()

  var freqConfig = {};
  switch (repeat.freq) {
    // case 1: // "Yearly On"
    //   freqConfig = {
    //     freq: RRule.YEARLY,
    //     dtstart: dayjs.utc(repeat.dtstart).toDate(),
    //     bymonth: repeat.bymonth + 1,
    //     bymonthday: repeat.bymonthday,
    //   };
    //   break;
    // case 2: // "Yearly On The"
    //   freqConfig = {
    //     freq: RRule.YEARLY,
    //     dtstart: dayjs.utc(repeat.dtstart).toDate(),
    //     bymonth: repeat.bymonth + 1,
    //     bysetpos: [weekOfMonth[repeat.bysetpos].value],
    //     byweekday: weekdays[repeat.weekday].value,
    //   };
    //   break;
    case 1: // "Monthly On"
      freqConfig = {
        freq: RRule.MONTHLY,
        dtstart: dtstart,
        bymonthday: repeat.bymonthday,
        interval: repeat.interval,
      };
      break;
    case 2: // "Monthly On The"
      freqConfig = {
        freq: RRule.MONTHLY,
        dtstart: dtstart,
        bysetpos: [weekOfMonth[repeat.bysetpos].value],
        byweekday: weekdays[repeat.weekday].value,
      };
      break;
    case 3: // "Weekly"
      freqConfig = {
        freq: RRule.WEEKLY,
        dtstart: dtstart,
        byweekday: repeat.weekdays
          .map((item, index) => {
            return item ? weekdays[index].value : false;
          })
          .filter((item) => item),
        interval: repeat.interval,
      };
      break;
    case 4: // "Daily"
      freqConfig = {
        freq: RRule.DAILY,
        interval: repeat.interval,
        dtstart: dtstart,
      };
      break;
    // case 7: // "Hourly"
    //   freqConfig = {
    //     freq: RRule.HOURLY,
    //     interval: repeat.interval,
    //     dtstart: dayjs.utc(repeat.dtstart).toDate(),
    //   };
    //   break;
  }
  // console.log("REPEAT FREQ CONFIG", freqConfig);

  const rule = new RRule({ ...freqConfig, ...endConfig, tzid: 'America/Toronto' });
  return rule;
};

export const generateRRuleSet = (repeat) => {
  const rruleSet = new RRuleSet();

  if (repeat) {
    const rule = generateRRule(repeat);
    // console.log("MONDAY", RRule.MO);
    // console.log("GENERARE REPEAT", repeat);
    // console.log("GENERARE RRULE", rule);
    rruleSet.rrule(rule);
  }

  if (repeat.rruleSet) {
    const exdates = repeat.rruleSet.exdates();
    exdates.forEach((exDate) => {
      rruleSet.exdate(exDate);
    });
  }

  return rruleSet;
};

export const getRRuleStarts = (repeat, startDate, endDate) => {
  return repeat.rruleSet.between(startDate, endDate);
};
