import React, { useCallback } from "react";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

import debounce from "lodash/debounce";

export const SearchBar = ({ searchQuery, setSearchQuery, handleSearch }) => {
  const onSubmit = (e) => {
    e.preventDefault();
    // handleSearch(e.target.value);
  };

  const debouncedSearch = useCallback(debounce((search_text) => handleSearch(search_text), 600), []);

  return (
    <Paper
      onSubmit={onSubmit}
      component="form"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 300 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        
        placeholder="Search Posts"
        inputProps={{ "aria-label": "search google maps" }}
        onInput={(e) => {
          // setSearchQuery(e.target.value);
          debouncedSearch(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            console.log('enter pressed')
            // handleSearch(e.target.value);
          }
        }}
      />
      <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};
