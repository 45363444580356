import React, { useState, useContext, useEffect } from "react";

import { UserContext } from "../../App";

import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { styled, lighten, darken } from "@mui/system";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  //   color: theme.palette.primary,
  //   color: theme.palette.primary.main,
  // backgroundColor:
  // theme.palette.mode === "light"
  //   ? lighten(theme.palette.primary.light, 0.85)
  //   : darken(theme.palette.primary.main, 0.8),
  //   backgroundColor: lighten(theme.palette.primary, 0.85),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

export default function SharingWidget({
  value,
  onChange,
  readOnly = false,
  label = "Shared with",
  allowEmpty = true,
}) {
  const { userState } = useContext(UserContext);
  const [sharingOptions, setSharingOptions] = useState([
    { title: "All Users", group: "groups" },
  ]);
  const [sharingValue, setSharingValue] = useState([]);


  const updateSharingValue = (sharing_options) => {

    // console.log("UPDATE SHARING VALUE", value )
    if (value && value.share_relationships) {
      const checked_everyone = value.share_everyone;
      const checked_relationships = value.share_relationships
        .substring(1, value.share_relationships.length - 1)
        .split(",")
        .map((item) => parseInt(item));
      const checked_users = value.share_users
        .substring(1, value.share_users.length - 1)
        .split(",")
        .map((item) => parseInt(item));

      var my_options = [];

      // console.log("checked_everyone", checked_everyone)
      // console.log("checked_relationships", checked_relationships)
      // console.log("checked_users", checked_users)

      sharing_options.forEach((item) => {
        if (item.group == "groups") {
          if (item.title === "All Users") {
            if (checked_everyone) {
              my_options.push(item);
            }
          } else {
            if (checked_relationships.includes(item.id)) {
              my_options.push(item);
            }
          }
        } else {
          if (checked_users.includes(item.id)) {
            my_options.push(item);
          }
        }
      });
      // console.log("SHARING OPTIONS", my_options);
      setSharingValue(my_options);
    }
  };

  const updateSharingOptions = () => {
    const sharing_list = [
      { key: 0, title: "All Users", group: "groups" },
      ...userState.relationships.map((item, index) => {
        return {
          key: index + 1,
          title: item.name,
          id: item.id,
          group: "groups",
        };
      }),
      ...userState.users.map((item, index) => {
        return {
          key: index + 100,
          title: item.first_name + " " + item.last_name,
          id: item.id,
          group: item.relationship.name,
        };
      }),
    ];
    // console.log("updateSharingOptions", sharing_list)
    setSharingOptions(sharing_list);

    // console.log ("updateSharingOptions readonly value", readOnly, value)
    if (readOnly === false && value === undefined) {
      const my_option = sharing_list.find(
        (x) => x.title == userState.currentUser.relationship.name
      );
      if (my_option && my_option.title !== "POA") {
        // console.log ("updateSharingOptions POA", my_option)
        handleChange([my_option]);
        setSharingValue([my_option]);
      } else {
        // console.log("updateSharingOptions sharing_list[0]", sharing_list[1], sharing_list[2])
        var initial_sharing_list = []
        sharing_list.forEach((item) => {
          if ((item.title == 'POA') || (item.title=='Caregiver')){
            initial_sharing_list.push(item)
          }

        })
        setSharingValue(initial_sharing_list);
        handleChange(initial_sharing_list);
      }
    } else {
      // console.log("updateSharingOptions call updateSharingValue", sharing_list)
      updateSharingValue(sharing_list);
    }
  };

  useEffect(() => {
    updateSharingOptions();
  }, [userState.relationships]);

  useEffect(() => {
    updateSharingValue(sharingOptions);
  }, [value]);

  useEffect(() => {
    updateSharingOptions();
  }, [userState.users]);

  useEffect(() => {
    updateSharingOptions();
  }, []);

  const handleChange = (newValue) => {
    // setSharingValue(newValue);

    // console.log("NEW VALUE", newValue);
    // console.log("SHARING VALUE", sharingValue);

    var checked_relationships = [];
    var checked_users = [];
    var checked_everyone = false;

    if (!allowEmpty && newValue.length === 0) {
      // Select POA as default option
      sharingOptions.every((item) => {
        if (item.title === "POA") {
          checked_relationships.push(item.id);
          return false;
        }
        return true;
      })
    } else {
      newValue.every((item) => {
        if (item.group === "groups") {
          if (item.title == "All Users") {
            if (
              sharingValue.length > 0 &&
              sharingValue[0].title !== "All Users"
            ) {
              checked_everyone = true;
              checked_relationships = [];
              checked_users = [];
              return false;
            }

            if (newValue.length > 1) {
              checked_everyone = false;
            } else {
              checked_everyone = true;
            }
          } else {
            checked_relationships.push(item.id);
          }
        } else {
          checked_users.push(item.id);
        }
        return true;
      });
    }

    const new_value = {
      share_relationships: "," + checked_relationships.join(",") + ",",
      share_users: "," + checked_users.join(",") + ",",
      share_everyone: checked_everyone,
    };
    onChange(new_value);
  };

  return (
    <Autocomplete
      multiple
      id="tags-standard"
      options={sharingOptions}
      getOptionLabel={(option) => option.title}
      //   defaultValue={[sharingOptions[0]]}
      value={sharingValue}
      readOnly={readOnly}
      limitTags={3}
      groupBy={(option) => {
        option.group;
      }}
      disableCloseOnSelect
      onChange={(e, value) => { handleChange(value) }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
        //   placeholder="Favorites"
        />
      )}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
}
