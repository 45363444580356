import { Scheduler } from "@aldabil/react-scheduler";
import { SchedulerRef } from "@aldabil/react-scheduler";

import { Button } from "@mui/material";
import React, { useState, useEffect, useContext, useRef } from "react";

import {
  format,
  addDays,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";
 
import {
  fetchRemote,
  confirmEvent,
  colorMap,
  handleDelete,
  fetchQueryToDates,
  fetchEvents,
  eventFields,
} from "./useSchedule";

// import { SchedulerRef } from "@aldabil/react-scheduler/types";

// import EventWidget from "./TabbedEventWidget";
import EventWidget from "./EventWidget";
// Schedule Blocks Impelmentation
//  https://codesandbox.io/s/custom-cell-action-n02dv?file=/src/App.js:608-767

export default function Schedule() {
  const [viewInterval, setViewInterval] = useState("week");
  const [viewStart, setViewStart] = useState();

  const schedulerRef = useRef(null);

  const getRemoteEvents = () => {
    var _viewStart = viewStart;
    var _viewInterval = viewInterval;

    if (!viewStart) {
      _viewStart = schedulerRef.current.scheduler.selectedDate;
      _viewInterval = schedulerRef.current.scheduler.view;
    }

    var query;
    if (_viewInterval === "day") {
      query = {
        start: _viewStart,
        end: _viewStart,
      };
    } else if (_viewInterval === "week") {
      query = {
        start: startOfWeek(_viewStart, { weekStartsOn: 1 }),
        end: endOfWeek(_viewStart, { weekStartsOn: 1 }),
      };
    } else if (_viewInterval === "month") {
      query = {
        start: startOfMonth(_viewStart),
        end: endOfMonth(_viewStart),
      };
    }

    fetchRemote(query).then((data) => {
      schedulerRef.current.scheduler.handleState(data, "events");
    });
  };

  const onEdit = (scheduler) => {
    var action;
    var event;
    if (scheduler.edited) {
      event = scheduler.edited;
      action = "edit";
    } else {
      event = {
        start: scheduler.state.start.value,
        end: scheduler.state.end.value,
      };
      action = "create";
    }

    return (
      <EventWidget
        event={event}
        onClose={scheduler.close}
        onConfirm={getRemoteEvents}
        // onDelete={onDelete}
        // scheduler={scheduler}
        action={action}
      />
    );
  };

  const onView = (event, close) => {
    return (
      <EventWidget
        event={event}
        onClose={close}
        onConfirm={getRemoteEvents}
        // onDelete={onDelete}
        // scheduler={scheduler}
        action={"edit"}
      />
    );
  };

  // const onDelete = (event_id) => {
  //   const events = schedulerRef.current.scheduler.events;

  //   const event = events.find((x) => x.event_id == event_id);

  //   handleDelete(event_id, event.db_id).then((data) => {
  //     getRemoteEvents();
  //   });
  // };

  useEffect(() => {
    getRemoteEvents();
  }, [viewStart, viewInterval]);
  // }, [viewInterval, viewStart]);

  useEffect(() => {
    // console.log("SCHEDULER REF", schedulerRef);
    setViewStart(schedulerRef.current.scheduler.selectedDate);
    window.scroll(0, window.innerHeight / 2 + 250);
  }, []);

  const startingHour = 0;  //temporary fix as react-scheduler is having trouble showing 2am
  const endingHour = 24;

  return (
    <Scheduler
      ref={schedulerRef}
      customEditor={onEdit}
      onViewChange={setViewInterval}
      onSelectedDateChange={setViewStart}
      draggable={false}
      customViewer={onView}
      view="week"
      fields={eventFields}
      stickyNavigation={true}
      timeZone={"America/Toronto"}
      // eventRenderer={(event) => {
      //   console.log("MY EVENT:", event);
      //   return (
      //     <div
      //       style={{
      //         display: "flex",
      //         flexDirection: "column",
      //         justifyContent: "space-between",
      //         height: "100%",
      //         background: "black",
      //         color: "white",
      //       }}
      //     >
      //       {/* <div
      //         style={{ height: 20, background: "#ffffffb5", color: "black" }}
      //       >
      //         {event.start.toLocaleTimeString("en-US", {
      //           timeStyle: "short",
      //         })}
      //       </div> */}
      //       <div style={{ height: 20, background: "yellow", color: "blue" }}>
      //         {event.event.title}
      //       </div>
      //       {/* <div
      //         style={{ height: 20, background: "#ffffffb5", color: "black" }}
      //       >
      //         {event.end.toLocaleTimeString("en-US", { timeStyle: "short" })}
      //       </div> */}
      //     </div>
      //   );
      // }}
      translations={{
        navigation: {
          month: "Month",
          week: "Week",
          day: "Day",
          today: "Current"
        },
        moreEvents: "More...",
        loading: "Loading...",
      }}
      week={{
        weekDays: [0, 1, 2, 3, 4, 5, 6],
        weekStartOn: 1,
        startHour: startingHour,
        endHour: endingHour,
        step: 60,
      }}
      month={{
        weekDays: [0, 1, 2, 3, 4, 5, 6],
        weekStartOn: 1,
        startHour: startingHour,
        endHour: endingHour,
        step: 60,
      }}
      day={{
        startHour: startingHour,
        endHour: endingHour,
        step: 60,
      }}
    />
  );
}
