import React, { useState, useEffect, useContext, useRef } from "react";

import { Scheduler } from "@aldabil/react-scheduler";
import { SchedulerRef } from "@aldabil/react-scheduler";
import { Button } from "@mui/material";
import { parseISO } from "date-fns";

import { authBackend, publicBackend } from "../Backend";

import NavbarPage from "../Pages/NavbarPage";

import {
  colorMap,
  fetchPublicEvents,
  confirmPublicEvent,
  eventFields,
} from "./useSchedule";

import PublicEventWidget from "./PublicEventWidget";

import {
  format,
  addDays,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";

export default function Schedule({ recipient }) {
  // const recipientId = 1;

  const hostpath = window.location.hostname.split(".");
  const subdomain = hostpath.length === 1 ? "stella" : hostpath[0];
  if (subdomain === "portal") {
    navigate("/login");
  }

  const [viewInterval, setViewInterval] = useState("week");
  const [viewStart, setViewStart] = useState();

  const schedulerRef = useRef(null);

  const getRemoteEvents = () => {
    // console.log("GET REMOTE EVENTS");
    var _viewStart = viewStart;
    var _viewInterval = viewInterval;

    if (!viewStart) {
      _viewStart = schedulerRef.current.scheduler.selectedDate;
      _viewInterval = schedulerRef.current.scheduler.view;
    }

    var query;
    if (_viewInterval === "day") {
      query = {
        start: _viewStart,
        end: _viewStart,
      };
    } else if (_viewInterval === "week") {
      query = {
        start: startOfWeek(_viewStart, { weekStartsOn: 1 }),
        end: endOfWeek(_viewStart, { weekStartsOn: 1 }),
      };
    } else if (_viewInterval === "month") {
      query = {
        start: startOfMonth(_viewStart),
        end: endOfMonth(_viewStart),
      };
    }

    fetchPublicEvents(query).then((data) => {
      schedulerRef.current.scheduler.handleState(data, "events");
    });
  };

  const onEdit = (scheduler) => {
    var action;
    var event;
    scheduler.close();
    return;
  };

  const onView = (event, close) => {
    return (
      <PublicEventWidget
        event={event}
        onClose={close}
        onConfirm={getRemoteEvents}
        // onDelete={onDelete}
        // scheduler={scheduler}
        action={"edit"}
      />
    );
  };

  useEffect(() => {
    getRemoteEvents();
  }, [viewStart, viewInterval]);
  // }, [viewInterval, viewStart]);

  useEffect(() => {
    // console.log("SCHEDULER REF", schedulerRef);
    setViewStart(schedulerRef.current.scheduler.selectedDate);
    // window.scroll(0, window.innerHeight / 2 + 250);
  }, []);

  const startingHour = 10;
  const endingHour = 23;

  return (
    <>
      {subdomain !== "portal" && (
        <>
          <NavbarPage recipient={recipient} />
          <Scheduler
            // customEditor={(scheduler) => (
            //   <PublicEventWidget scheduler={scheduler} />
            // )}
            // viewerExtraComponent={(fields, event) => {
            //   return (
            //     <div>
            //       <br />
            //       <p>Edit this event to book a time...</p>
            //     </div>
            //   );
            // }}
            deletable={false}
            // getRemoteEvents={fetchPublicEvents}
            draggable={false}
            ref={schedulerRef}
            customEditor={onEdit}
            onViewChange={setViewInterval}
            onSelectedDateChange={setViewStart}
            customViewer={onView}
            // onDelete={handleDelete}
            view="week"
            stickyNavigation={true}
            fields={eventFields}
            // month={null}
            day={null}
            week={{
              weekDays: [0, 1, 2, 3, 4, 5, 6],
              weekStartOn: 1,
              startHour: startingHour,
              endHour: endingHour,
              step: 60,
            }}
            month={{
              weekDays: [0, 1, 2, 3, 4, 5, 6],
              weekStartOn: 1,
              startHour: startingHour,
              endHour: endingHour,
              step: 60,
            }}
            translations={{
              navigation: {
                month: "Month",
                week: "Week",
                day: "Day",
                today: "Current",
              },
              moreEvents: "More...",
              loading: "Loading...",
            }}
            // month={{
            //   weekDays: [0, 1, 2, 3, 4, 5, 6],
            //   weekStartOn: 0,
            //   startHour: startingHour,
            //   endHour: endingHour,
            //   step: 60,
            // }}
            // day={{
            //   startHour: startingHour,
            //   endHour: endingHour,
            //   step: 60,
            // }}
          />{" "}
        </>
      )}
    </>
  );
}
