// const url =
//   process.env.REACT_APP_API_PORT === "80"
//     ? (window.location.protocol + "//" + window.location.hostname)
//     : ( window.location.protocol +
//       "//" +
//       window.location.hostname +
//       ":" +
//       process.env.REACT_APP_API_PORT);

const url = (window.location.protocol + "//" + window.location.hostname)
    
import axios from "axios";
import rison from "rison";

export const publicBackend = async ({
  method = "get",
  path = "",
  filters = [],
  page = 1,
  page_size = 0,
  order_column = "",
  order_direction = "asc",
  payload = undefined,
} = {}) => {
  // var recipient_id = undefined;
  // console.log("RECIPIENT ID");
  // console.log(localStorage.recipient_id);

  // if (localStorage.recipient_id !== "undefined") {
  //   console.log("made it through undefiend");
  //   recipient_id =
  //     localStorage.recipient_id && JSON.parse(localStorage.recipient_id);

  //   if (payload === undefined) {
  //     payload = { recipient_id: recipient_id };
  //   }else{
  //     payload.recipient_id = recipient_id;
  //   }
  // }

  var querystring;
  if (filters.length !== 0) {
    querystring =
      "?q=" +
      rison.encode({
        filters: filters,
        // columns: ["title", "recipient"],
        // filters: [
        //   {
        //     col: "recipient_id",
        //     opr: "eq",
        //     value: 3,
        //   },
        // ],
        // keys: ["list_columns"],
      });
  } else {
    querystring = "";
  }

  try {
    const { data } = await axios({
      method: method,
      url: `${url}/api/v1/${path}${querystring}`,
      data: payload,
    });
    if (data) {
      return data;
    }
  } catch (err) {
    if (err && err.response) {
      throw err.response.data;
    }
  }
};

export const authBackend = async ({
  method = "get",
  path = "",
  filters = [],
  page = -1,
  page_size = -1,
  order_column = "",
  order_direction = "asc",
  payload = undefined,
} = {}) => {
  let token = JSON.parse(localStorage.token);

  // const querystring =
  // "?q=" +
  // rison.encode({
  //   filters: filters,
  //   // columns: ["title", "recipient"],
  //   // filters: [
  //   //   {
  //   //     col: "recipient_id",
  //   //     opr: "eq",
  //   //     value: 3,
  //   //   },
  //   // ],
  //   // keys: ["list_columns"],
  //   order_column: "created_at",
  //   order_direction: "desc",
  //   page: pageNum,
  //   page_size: 10,
  // });

  // var recipient_id = undefined;
  // console.log("RECIPIENT ID");
  // console.log(localStorage.recipient_id);

  // if (localStorage.recipient_id !== "undefined") {
  //   console.log("made it through undefiend");
  //   recipient_id =
  //     localStorage.recipient_id && JSON.parse(localStorage.recipient_id);

  //   filters.push({"col": "recipient_id", "opr": "eq", "value": recipient_id})

  //   if (payload === undefined) {
  //     payload = { recipient_id: recipient_id };
  //   }else{
  //     payload.recipient_id = recipient_id;
  //   }
  // }

  // console.log("USERSTATE");
  // console.log(userState.selectedRecipient);

  try {
    var querystring;
    var querydata = {};

    if (filters.length !== 0) {
      querydata.filters = filters;
    }

    if (page >= 0) {
      querydata.order_column = order_column;
      querydata.order_direction = order_direction;
      querydata.page = page;
      querydata.page_size = page_size;
    }

    if (Object.keys(querydata).length > 0) {
      querystring = "?q=" + rison.encode(querydata);
    } else {
      querystring = "";
    }

    const { data } = await axios({
      method: method,
      url: `${url}/api/v1/${path}${querystring}`,
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("AUTHDATA");
    // console.log(data)
    if (data) {
      return data;
    }
  } catch (err) {
    // pretty sure there is a much better way of handling this.
    // THis does not result in the error being throw by the upstream promise.

    if (err && err.response) {
      throw err.response.data;
    }

    // return { error: err.response.data.message };
    // throw new Error(err.response.data);
    // if (err && err.response) {
    //   return {
    //     error: err.response.data.message,
    //   };
    // }
  }
};

const getModelList = async (
  model = "",
  filters = [],
  page = 1,
  page_size = 0,
  order_column = "",
  order_direction = "asc"
) => {
  let token = JSON.parse(localStorage.token);

  // Expected query =
  // ?start=Sun Dec 11 2022 00:00:00 GMT-0500 (Eastern Standard Time)&end=Sat Dec 17 2022 23:59:59 GMT-0500 (Eastern Standard Time)

  const querystring =
    "?q=" +
    rison.encode({
      filters: filters,
      // columns: ["title", "recipient"],
      // filters: [
      //   {
      //     col: "recipient_id",
      //     opr: "eq",
      //     value: 3,
      //   },
      // ],
      // keys: ["list_columns"],
      order_column: "created_at",
      order_direction: "desc",
      page: pageNum,
      page_size: 10,
    });

  try {
    const { data } = await axios.get(`${url}/api/v1/${model}/${querystring}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("AUTHBACKEND DATA")
    // console.log(data)

    if (data) {
      const result_out = data.result.map((item, index) => ({
        ...item,
        id: data.ids[index],
        // document_type: item.document_type.name,
      }));
      return {
        ...data,
        result: result_out,
        pagination: {
          totalPage: Math.ceil(response.data.count / querystring.page_size),
          currentPage: querystring.pageNum,
        },
      };
    }
  } catch (err) {
    if (err && err.response) {
      return {
        error: err.response.data,
      };
    }
  }
};
