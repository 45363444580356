import React, { useContext, useState } from "react";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import { fetchCurrentUser } from "./AuthService";
import { loadRelationships } from "../Relationships/useRelationships";
import { loadUsers } from "../Users/useUsers";

import { authBackend, publicBackend } from "../Backend";

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Link,
} from "@mui/material";

import Form from "@rjsf/mui";

import validator from "@rjsf/validator-ajv6";

// import { withTheme } from '@rjsf/core';
// import Theme from '@rjsf/mui';
// Make modifications to the theme with your own fields and widgets
// const Form = withTheme(Theme);

import { UIContext, UserContext } from "../../App";

const schema = {
  title: "SeniorSynCare Login",
  type: "object",
  required: ["username", "password"],
  properties: {
    username: {
      type: "string",
      title: "Email",
      format: "email",
      minLength: 3,
    },
    password: {
      type: "string",
      title: "Password",
      minLength: 3,
    },
    remember: {
      type: "boolean",
      title: "Remember me",
    },
  },
};

const ui_schema = {
  username: {
    "ui:autofocus": true,
    // "ui:description": "Login Username",
  },
  password: {
    // "ui:description": "Login password",
    "ui:widget": "password",
  },
  "ui:submitButtonOptions": {
    submitText: "Login",
    norender: false,
    props: {
      disabled: false,
      className: "btn btn-info",
    },
  },
};

const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
};

const Login = () => {
  const { userState } = useContext(UserContext);
  const { uiDispatch } = useContext(UIContext);
  const { userDispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const recipientId =
    localStorage.recipient_id && JSON.parse(localStorage.recipient_id);

  const formSubmit = async ({ formData }, e) => {
    // console.log("Form Data: ",  formData);

    const submit_data = {
      refresh: true,
      provider: "db",
      username: formData["username"],
      password: formData["password"],
    };

    setLoading(true);

    await publicBackend({
      method: "post",
      path: "security/login",
      payload: submit_data,
    })
      .then((data) => {
        // console.log("Setting token", data);
        localStorage.setItem("token", JSON.stringify(data.access_token));

        asyncLocalStorage
          .setItem("token", JSON.stringify(data.access_token))
          .then(function () {
            return asyncLocalStorage.getItem("token");
          })
          .then(function (value) {
            fetchCurrentUser().then((me) => {
              // uiDispatch({
              //   type: "SET_MESSAGE",
              //   payload: {
              //     color: "success",
              //     display: true,
              //     text: "Successful Login",
              //   },
              // });

              // localStorage.setItem(
              //   "recipient_id",
              //   JSON.stringify(me.data.user.recipients[0].id)
              // );

              const hostpath = window.location.hostname.split(".");
              const current_subdomain =
                hostpath.length === 1 ? "stella" : hostpath[0];

              // console.log("SUBDOMAIN IS ", current_subdomain);
              // console.log("PROTOCOL IS ", window.location.protocol);
              // console.log("Logged in recipient is ");
              // console.log(me);
              const my_recipient_subdomain =
                me.data.user.recipients[0].subdomain;
              // console.log(my_recipient_subdomain);
              if (current_subdomain == my_recipient_subdomain) {
                userDispatch({
                  type: "SET_CURRENT_USER",
                  payload: me.data.user,
                });

                loadRelationships().then((data) => {
                  userDispatch({
                    type: "SET_RELATIONSHIPS",
                    payload: data.result,
                  });
                });

                loadUsers().then((data) => {
                  userDispatch({
                    type: "SET_USERS",
                    payload: data.result,
                  });
                });

                navigate("/home");
              } else {
                localStorage.removeItem("token");

                // window.location.assign(
                //   `http://${my_recipient_subdomain}.waitingforgod.ca/login`
                // );

                uiDispatch({
                  type: "SET_MESSAGE",
                  payload: {
                    color: "success",
                    display: true,
                    // text: `Successful Login. Redirecting to ${window.location.protocol}//${my_recipient_subdomain}.waitingforgod.ca ...`,
                    text: `Successful Login. Redirecting to ${window.location.protocol}//${my_recipient_subdomain}.seniorsyncare.com ...`,
                  },
                });

                // const redirect = `${window.location.protocol}//${my_recipient_subdomain}.waitingforgod.ca/login/${data.access_token}`;
                const redirect = `${window.location.protocol}//${my_recipient_subdomain}.seniorsyncare.com/login/${data.access_token}`;

                console.log("Redirecting to:", redirect);
                window.location.assign(redirect);
              }
            });
            setLoading(false);
            console.log("Value has been set to:", value);
          });
      })
      .catch((err) => {
        uiDispatch({
          type: "SET_MESSAGE",
          payload: {
            color: "error",
            display: true,
            text: err.message,
          },
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Box
        width={"50vh"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        m="auto"
      >
        <Paper variant="elevation" elevation={2}>
          <Grid
            container
            direction="column"
            justify="center"
            spacing={2}
            padding={2}
          >
            <Grid item>
              <Form
                schema={schema}
                uiSchema={ui_schema}
                validator={validator}
                onSubmit={formSubmit}
              >
                <div>
                  <Button
                    type="submit"
                    disabled={loading}
                    style={{
                      width: "100%",
                      // background: "rgb(24,119,242)",
                      // color: "#fff",
                      marginTop: "16px",
                      height: 70,
                    }}
                    size="large"
                    variant="contained"
                  >
                    {loading ? (
                      <CircularProgress
                        variant="indeterminate"
                        size={25}
                        style={{ color: "#fff" }}
                      />
                    ) : (
                      "Log In"
                    )}
                  </Button>
                </div>
              </Form>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/reset" variant="body2">
                Forgot Password?
              </Link>
            </Grid>
            {/* {1 == 1 && ( */}
            {recipientId === 0 && (
              <Grid item>
                <p>If not already signed up, register here:</p>
                <Box
                  width={"50vh"}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  m="auto"
                >
                  <Button
                    style={{
                      width: "100%",
                      // color: 'secondary',
                      background: "#B091C3",
                      color: "#fff",
                      marginTop: "16px",
                    }}
                    size="small"
                    onClick={() => {
                      navigate("/register");
                    }}
                    // variant="outlined"
                  >
                    Register
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default Login;
