import React, { useContext } from "react";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import "./Home.css";
import NavbarPage from "./NavbarPage";

import {
  Box,
  Button,
  IconButton,
  Container,
  Divider,
  Grid,
  Paper,
  Link,
  Typography,
} from "@mui/material";

import { UserContext } from "../../App";

const Home = ({ recipient }) => {
  const navigate = useNavigate();

  const { userState, userDispatch } = useContext(UserContext);
  const hostpath = window.location.hostname.split(".");
  const subdomain = hostpath.length === 1 ? "stella" : hostpath[0];
  if (subdomain === "portal") {
    navigate("/login");
  }

  return (
    <>
      {subdomain !== "portal" && (<>
        <NavbarPage recipient={recipient} />

        <Typography variant='h5' style={{ color: "#095296" }}><strong>Community of care for your dependent senior</strong></Typography>

        <br/>
        <Typography>
          Welcome to the personal SeniorSynCare website for {recipient.nickname}!
        </Typography>
        <br/>
        <Typography>
          Experiencing the end of a life is difficult, but it can be deep
          and meaningful with the right community of care. We are here to
          ensure {recipient.nickname} has the best community of care
          possible, allowing her family and friends to engage in fellowship
          and loving relationship.

        </Typography>
      </>)}
    </>
  );
};

export default Home;
