import React, { useState, useRef, useEffect } from "react";
import { WidgetProps } from "@rjsf/core";
import FormLabel from "@mui/material/FormLabel";
import List from "@mui/material/List";
import Input from "@mui/material/Input";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import DescriptionIcon from "@mui/icons-material/Description";

import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";

import Paper from '@mui/material/Paper';


import { dataURItoBlob } from "@rjsf/utils";

function addNameToDataURL(dataURL, name) {
  return dataURL.replace(";base64", `;name=${encodeURIComponent(name)};base64`);
}

function processFile(file) {
  const { name, size, type } = file;
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onerror = reject;
    reader.onload = (event) => {
      resolve({
        dataURL: addNameToDataURL(event.target?.result, name),
        name,
        size,
        type,
      });
    };
    reader.readAsDataURL(file);
  });
}

function processFiles(files) {
  return Promise.all([].map.call(files, processFile));
}

function extractFileInfo(dataURLs) {
  return dataURLs
    .filter((dataURL) => typeof dataURL !== "undefined")
    .map((dataURL) => {
      const { blob, name } = dataURItoBlob(dataURL);
      return {
        name: name,
        size: blob.size,
        type: blob.type,
      };
    });
}

const FilesInfo = ({ filesInfo }) => {
  if (!filesInfo || filesInfo.length === 0) {
    return null;
  }
  return (
    <List id="file-info">
      {filesInfo.map((fileInfo, key) => {
        const { name, size, type } = fileInfo;
        return (
          <ListItem key={key}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary={name} secondary={`${type}, ${size} bytes`} />
          </ListItem>
        );
      })}
    </List>
  );
};

export const FilesInfoWidget = ({
  id,
  schema,
  options,
  value,
  required,
  disabled,
  readonly,
  label,
  multiple,
  autofocus,
  onChange,
}) => {
  const [state, setState] = useState();
  const inputRef = useRef();

  useEffect(() => {
    const values = Array.isArray(value) ? value : [value];
    const initialFilesInfo = extractFileInfo(values);
    if (initialFilesInfo.length > 0) {
      setState(initialFilesInfo);
    }
  }, [value]);

  return (
    <>
      <FormLabel required={required} htmlFor={id}>
        {label || schema.title}
      </FormLabel>
      {/* <FormHelperText>{ "" || schema.description }</FormHelperText> */}

      {/* <InputLabel htmlFor="component-outlined">
        {label || schema.title}
      </InputLabel> */}
      {/* <OutlinedInput
          id="component-outlined"
          value={{label || schema.title}}
          onChange={handleChange}
          label="Name"
        /> */}

      {/* <OutlinedInput
        label={label}
        ref={inputRef.current}
        id={id}
        type="file"
        value={state}
        disabled={readonly || disabled}
        autoFocus={autofocus}
        multiple={multiple}
        accept={options.accept}
      /> */}
      {/* <Paper variant="outlined" > */}
      <FilesInfo filesInfo={state} />
      {/* </Paper> */}
    </>
  );
};

const FileWidget = ({
  id,
  schema,
  options,
  value,
  required,
  disabled,
  readonly,
  label,
  multiple,
  autofocus,
  onChange,
}) => {
  const [state, setState] = useState();
  const inputRef = useRef();

  useEffect(() => {
    const values = Array.isArray(value) ? value : [value];
    const initialFilesInfo = extractFileInfo(values);
    if (initialFilesInfo.length > 0) {
      setState(initialFilesInfo);
    }
  }, [value]);

  const handleChange = (event) => {
    processFiles(event.target.files).then((filesInfo) => {
      setState(filesInfo);
      const values = filesInfo.map((fileInfo) => fileInfo.dataURL);
      if (multiple) {
        onChange(values);
      } else {
        onChange(values[0]);
      }
    });
  };

  return (
    <>
      <FormLabel required={required} htmlFor={id}>
        {label || schema.title}
      </FormLabel>
      {/* <FormHelperText>{ "" || schema.description }</FormHelperText> */}

      {/* <InputLabel htmlFor="component-outlined">
        {label || schema.title}
      </InputLabel> */}
      {/* <OutlinedInput
          id="component-outlined"
          value={{label || schema.title}}
          onChange={handleChange}
          label="Name"
        /> */}

      <OutlinedInput
        label={label}
        ref={inputRef.current}
        id={id}
        type="file"
        // value={""}
        disabled={readonly || disabled}
        onChange={handleChange}
        autoFocus={autofocus}
        multiple={multiple}
        accept={options.accept}
      />
      <FilesInfo filesInfo={state} />
    </>
  );
};

export default FileWidget;
