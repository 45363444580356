import React, { useState, useEffect, useContext } from "react";

import JsxParser from "react-jsx-parser";
// import Listing from "../Builder/plugins/Listing";
import { Button as MuiButton, Slider } from "@mui/material";

import { LogoutUser } from "../Auth/AuthService";
import { UIContext, UserContext } from "../../App";

import { Link, useNavigate } from "react-router-dom";

import NavbarPage from "./NavbarPage";

import "./Page.css";
import { getPage } from "./Pages";

export const Page = ({ index, pageinfo }) => {
  const [page_info, setPageInfo] = useState({});
  const [nav_info, setNavInfo] = useState({});
  const [page_index, setPageIndex] = useState();

  const { userState, userDispatch } = useContext(UserContext);
  const { uiDispatch } = useContext(UIContext);

  const navigate = useNavigate();

  // console.log("IN PAGE!");
  // console.log(userState);

  // console.log(index)
  // console.log(page_index)

  if (index != page_index) {
    setPageIndex(index);

    pageinfo.pages.forEach((page) => {
      if (page.page_template.title == "Navbar") {
        getPage(page.id, setNavInfo);
      }
    });

    if (index !== undefined) {
      getPage(pageinfo.pages[index].id, setPageInfo);
    }
  }

  function fix_navigation(old_html) {
    const regex = /<a(.*?)href=\"\/(.*?)\"(.*?)<\/a>/gi;
    return old_html.replaceAll(regex, '<Link$1to="/$2"$3</Link>');
  }
  return (
    <>
      {/* <Page pageinfo={{ index: 2, pages: pages }} index={2} /> */}
      {/* {nav_info.page_template && (
        <>
          <JsxParser
            bindings={{
              name: userState.currentUser.first_name,
              loggedin: userState.isLoggedIn ? "" : "Login",
              recipient: { name: userState.selectedRecipient.nickname },
            }}
            components={{ Link, MuiButton, Slider }}
            jsx={fix_navigation(nav_info.page_template.html)}
          />
          <style>{nav_info.page_template.css}</style>
        </>
      )} */}

      <NavbarPage />


      {/* { (index > 0) && (page_info.page_template) && ( */}
      {page_info.page_template && (
        <>
          <JsxParser
            bindings={{
              name: userState.currentUser.first_name,
              loggedin: userState.isLoggedIn ? "" : "Login",
              recipient: { name: userState.selectedRecipient.nickname },
            }}
            components={{ Link, MuiButton, Slider }}
            jsx={fix_navigation(page_info.page_template.html)}
          />
          <style>{page_info.page_template.css}</style>
        </>
      )}
    </>
  );
};
