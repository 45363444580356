import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Link,
  CircularProgress,
} from "@mui/material";

import { authBackend } from "../Backend";
import { UIContext, UserContext } from "../../App";

// const url =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   ":" +
//   process.env.REACT_APP_API_PORT;

export const SubmitModal = ({
  modalOpen = false,
  submitting = false,
  formData,
}) => {
  const navigate = useNavigate();

  console.log("SUBMIT FORMDATA", formData);

  const navigateToSite = () => {
    console.log(
      // `Redirecting to https://${formData.subdomain}.waitingforgod.ca/login`
      `Redirecting to https://${formData.subdomain}.seniorsyncare.com/login`
    );
    window.location.assign(
      // `https://${formData.subdomain}.waitingforgod.ca/login`
      `https://${formData.subdomain}.seniorsyncare.com/login`
      // `${protocol}//${my_recipient_subdomain}.waitingforgod.ca/login/${data.access_token}`
    );
  };

  return (
    <>
      <Modal
        open={modalOpen}
        // onClose={handleClose}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
        sx={{
          //   width: "100%",
          //   maxWidth: "100vw",
          //   maxHeight: "100%",
          //   position: "fixed",
          top: "20%",
          //   left: "0",
          //  transform: "translate(0, -50%)",
          // overflowY: "auto",
        }}
      >
        <Box
          // width={"80vh"}
          display="flex"
          flexDirection="center"
          alignItems="center"
          justifyContent="center"
          // m="auto"
        >
          <Paper variant="elevation" elevation={2}>
            <Grid
              container
              direction="column"
              justify="center"
              spacing={2}
              padding={2}
            >
              <Grid item>
                <Typography
                  component="h1"
                  variant="h4"
                  sx={{
                    color: "#1976d2",
                    // textTransform: "uppercase",
                    fontWeight: 700,
                  }}
                >
                  Registration Processing
                </Typography>

                {submitting && (
                  <>
                    <p>Setting up profile now.</p>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "light"
                              ? "#1a90ff"
                              : "#308fe8",
                          animationDuration: "1500ms",
                          // position: "absolute",
                          // left: 0,
                          // [`& .${circularProgressClasses.circle}`]: {
                          //   strokeLinecap: "round",
                          // },
                        }}
                        size={100}
                        thickness={4}
                        // {...props}
                      />
                    </Box>
                    <p>Please wait...</p>
                  </>
                )}

                {!submitting && (
                  <>
                    <p>Registration Complete!</p>
                    <p>Thank you for SeniorSynCare Registration! </p>
                    {/* <p>Order ID: {formData.orderID}</p>
                    <p>Subscription ID: {formData.subscriptionID}</p> */}
                    <p>You will shortly receive a confirmation email.</p>
                    <p>You will then receive a login email once your seniors' custom site is ready within 24 hours.</p>
                    {/* <p>Click the link below to login to your new domain:</p>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mb: 2,
                      }}
                    >
                      <Link
                        component="button"
                        variant="contained"
                        onClick={navigateToSite}
                      >
                        https://{formData.subdomain}.waitingforgod.ca/login
                      </Link>
                    </Box>
                    <p>You can also login via the general SeniorSynCare login.</p> */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        Back to Main Login
                      </Button>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};
