import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
  createRef,
  useRef,
} from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import FolderIcon from "@mui/icons-material/Folder";
import PageviewIcon from "@mui/icons-material/Pageview";
import AssignmentIcon from "@mui/icons-material/Assignment";

import { RelationShipAvatars, RelationshipAvatar } from "./RelationshipAvatar";

import ColorPicker from "react-pick-color";

// // https://viclafouch.github.io/mui-color-input
// import { MuiColorInput } from "mui-color-input";

export const IconSelectWidget = (props) => {
  const get_value = (prop_key) => {
    const prop_json = JSON.parse(props.value);
    return prop_json[prop_key];
  };

  const put_value = (prop_key, value) => {
    console.log("INPUT PROPS", props);
    var prop_json = JSON.parse(props.value);
    prop_json[prop_key] = value;

    const json_string = JSON.stringify(prop_json);
    props.onChange(json_string);
    return json_string;
  };

  const [relationshipIcon, setRelationshipIcon] = useState(
    RelationShipAvatars[0].name
  );
  const [relationshipColor, setRelationshipColor] = useState("#d63cd9");

  const update_selects = () => {
    var prop_json;
    if (!props.value) {
      prop_json = { name: relationshipIcon, color: relationshipColor };
      const json_string = JSON.stringify(prop_json);
      props.onChange(json_string);
    } else {
      prop_json = JSON.parse(props.value);
      if (prop_json["name"].length) {
        setRelationshipIcon(prop_json["name"]);
      }
      if (prop_json["color"].length) {
        setRelationshipColor(prop_json["color"]);
      }
    }
  };

  useEffect(() => {
    update_selects();
  }, [props.value]);

  return (
    <>
      <div>
        <Stack direction="row" spacing={2}>
          <FormControl sx={{ mr: 1, width: 140 }}>
            <InputLabel id="iconLabel">Icon</InputLabel>
            <Select
              value={relationshipIcon}
              label="Icon"
              onChange={(e) => {
                put_value("name", e.target.value);
              }}
            >
              {RelationShipAvatars.map((item) => (
                <MenuItem key={item.name} value={item.name}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <RelationshipAvatar
                      size={40}
                      config={{ name: item.name, color: relationshipColor }}
                    />
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <ColorPicker
            hideAlpha={true}
            hideInputs={true}
            presets={[
              "red",
              "blue",
              "green",
              "yellow",
              "crimson",
              "grey",
              "lightgrey",
              "white",
              "#000",
              "purple",
              "papayawhip",
              "lightgreen",
              "pink",
            ]}
            color={relationshipColor}
            onChange={(color) => {
              console.log("PUT COLOR", color.hex);
              put_value("color", color.hex);
            }}
          />

          {/* <MuiColorInput
          sx={{ ml: 1, width: 140 }}
          isAlphaHidden
          label="Icon Color"
          format="hex"
          value={relationshipColor}
          onChange={(color) => {
            put_value("color", color);
          }}
        /> */}
        </Stack>
      </div>
    </>
  );
};
