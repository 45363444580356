import React, { useContext, useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { UIContext, UserContext } from "../../App";

import { fetchCurrentUser } from "./AuthService";

const AutoLogin = () => {
  const { uiDispatch } = useContext(UIContext);
  const [loading, setLoading] = useState(false);
  const { userState, userDispatch } = useContext(UserContext);

  const navigate = useNavigate();

  let { loginToken } = useParams();

  console.log("AUTO LOGIN");

  const setLogin = () => {
    console.log("AutoLogin setting token.");
    // console.log(loginToken);

    localStorage.setItem("token", JSON.stringify(loginToken));

    navigate('/home');
    // window.location.assign(`${window.location.protocol}//${window.location.hostname}/home`);
    return;

    fetchCurrentUser()
      .then((me) => {
        // uiDispatch({
        //   type: "SET_MESSAGE",
        //   payload: {
        //     color: "success",
        //     display: true,
        //     text: "Successful Login",
        //   },
        // });
        // console.log("AutoLogin fetchCurrentUser ");
        // console.log("Setting user to ", me);
        // console.log("Setting user to ", me.data.user);
        // userDispatch({ type: "SET_CURRENT_USER", payload: me.data.user });

        // uiDispatch({
        //   type: "SET_MESSAGE",
        //   payload: {
        //     color: "success",
        //     display: true,
        //     text: "Successful Login",
        //   },
        // });

        window.location.assign(`http://${window.location.hostname}/`);
        // navigate("/home");
      })
      .catch((err) => {
        console.log("AutoLoginfetchCurrentUser ERROR");
        console.log(err);
      });
  };

  useEffect(() => {
    setLogin();
  }, []);

  return (
    <>
      <p>AutoLogin Navigation Failure</p>
    </>
  );
};

export default AutoLogin;
