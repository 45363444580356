import { useContext, useState } from "react";
// import axios from "axios";
import { UIContext } from "../../App";
import { useNavigate } from "react-router-dom";

import { authBackend } from "../Backend";

const useCreatePost = ({
  update_id,
  message,
  date,
  privacy,
  postImages,
  handleSubmit,
  logSharing,
}) => {
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  const { uiDispatch } = useContext(UIContext);
  const navigate = useNavigate();

  const createPost = async (data) => {
    setLoading(true);

    const submit_method = data.id ? "patch" : "post";

    authBackend({
      method: submit_method,
      path: "post/",
      payload: data,
    })
      .then((data) => {
        handleSubmit({ id: data.id, ...data.result });

        setLoading(false);
        // postDispatch({ type: 'ADD_POST', payload: response.data.post })
        // uiDispatch({
        //   type: "SET_MESSAGE",
        //   payload: {
        //     color: "success",
        //     display: true,
        //     text: data.message,
        //   },
        // });
        uiDispatch({ type: "SET_POST_MODEL", payload: false });
        navigate("/discussions");
      })
      .catch((err) => {
        setLoading(false);
        if (err && err.response) {
          uiDispatch({
            type: "SET_MESSAGE",
            payload: {
              display: true,
              text: err.response.data.error,
              color: "error",
            },
          });
        }
        console.log(err);
      });

    // let token = localStorage.token && JSON.parse(localStorage.getItem("token"));

    // try {
    //   const response = await axios.post(`${url}/api/v1/post/`, data, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });

    //   handleSubmit({ id: response.data.id, ...response.data.result });

    //   setLoading(false);
    //   // postDispatch({ type: 'ADD_POST', payload: response.data.post })
    //   uiDispatch({
    //     type: "SET_MESSAGE",
    //     payload: {
    //       color: "success",
    //       display: true,
    //       text: response.data.message,
    //     },
    //   });
    //   uiDispatch({ type: "SET_POST_MODEL", payload: false });
    //   navigate("/discussions");
    // } catch (err) {
    //   setLoading(false);
    //   if (err && err.response) {
    //     uiDispatch({
    //       type: "SET_MESSAGE",
    //       payload: {
    //         display: true,
    //         text: err.response.data.error,
    //         color: "error",
    //       },
    //     });
    //   }
    //   console.log(err);
    // }
  };

  const createUserPost = async (images = []) => {
    const recipient_id = JSON.parse(localStorage.recipient_id);

    console.log("POST DATA MESAGE", message, message ? true : false);
    console.log(
      "POST DATA images",
      postImages,
      postImages.length ? true : false
    );

    if (message || postImages.length) {

      var submit_data = {
        date: date.format("YYYY-MM-DD"),
        message: message,
        privacy: "1",
        recipient: recipient_id,
        images: postImages,
        share_everyone: logSharing ? logSharing.share_everyone : false,
        share_relationships: logSharing ? logSharing.share_relationships : ",,",
        share_users: logSharing ? logSharing.share_users : ",,",
      };
      if (update_id) {
        submit_data.id = update_id
      }
      await createPost(submit_data);
      setError("");
    } else {
      setError("Message must contain either text or an image!");
    }

    return;
  };

  const handleSubmitPost = () => {
    createUserPost();
  };

  return {
    handleSubmitPost,
    loading,
    error,
  };
};

export default useCreatePost;
