import { authBackend, publicBackend } from "../Backend";

// Schedule Blocks Impelmentation
//  https://codesandbox.io/s/custom-cell-action-n02dv?file=/src/App.js:608-767

// TEST CONFIGURATION
// export const PayPalConfig = {
//   clientId:
//     "AZOTLwjUxzc00l-GnHIDjB2dEmYgkvUUwGMeX0nRBf1tPjNXAzkgiojthiOpjCQZHX3Jp2CIo-FwlC7i",
//   plans: [
//     {
//       const: "P-0S733165NL516073GMTRHUZA",
//       title: "Monthly [ $24.99/month + tax ]",
//     },
//     {
//       const: "P-7LR26803SC7708426MTRHVZI",
//       title: "Semi-Annual (15% discount) [ $21.25/month + tax ]",
//     },
//   ],
// };

// // OFFICIAL CONFIGURATION
export const PayPalConfig = {
  clientId:
    "ATSa8qjQLXWEvkJ2aTalBw44ZW7I0NlGvUN56GBVMHU9xGDWrXK_DSTeuqtzx-UmEswh_6M5TEcI0sHk",
  plans: [
    {
      const: "P-484914607H0770052MXLGR5Y",
      title: "Free Trial + Monthly [ $24.99/month + tax ]",
    },
    // {
    //   const: "P-4H377187VU6131143MTQSUYQ",
    //   title: "Monthly [ $24.99/month + tax ]",
    // },

    {
      const: "P-45P76658EA943144BMZTSTYA",
      title: "3-Month Trial [ $14.99/month + tax ] + 3-Month Renewals [ $24.99/month + tax ]",
    },
    // {
    //   const: "P-4CJ21484JS304852UMTR2FAI",
    //   title: "Semi-Annual (15% discount) [ $42.49/month + tax ]",
    // },
  ],
};

const userExists = async (username = "") => {
  const data = await publicBackend({
    // const { data } = await publicBackend({
    path: "find/",
    filters: [{ col: "email", opr: "eq", value: username }],
  }).then((data) => {
    return data;
  });

  return data.result.exists;

  // const querystring = rison.encode({
  //   filters: [
  //     {
  //       col: "email",
  //       opr: "eq",
  //       value: username,
  //     },
  //   ],
  // });
  // const { data } = await axios.get(`${url}/api/v1/find/?q=${querystring}`);

  // return data.result.exists;
};

const recipientExists = async (recipient = "") => {
  const data = await publicBackend({
    path: "find/",
    filters: [{ col: "subdomain", opr: "eq", value: recipient }],
  }).then((data) => {
    // console.log("userExists");
    // console.log(data);

    return data;
  });

  return data.result.exists;

  // const querystring = rison.encode({
  //   filters: [
  //     {
  //       col: "recipient",
  //       opr: "eq",
  //       value: recipient,
  //     },
  //   ],
  // });

  // const { data } = await axios.get(`${url}/api/v1/find/?q=${querystring}`);

  // return data.result.exists;
};

const validLogin = async (username, password) => {
  const submit_data = {
    refresh: true,
    provider: "db",
    username: username,
    password: password,
  };

  return await publicBackend({
    method: "post",
    path: "security/login",
    payload: submit_data,
  })
    .then((res) => {
      if ("access_token" in res.data) {
        return true;
      } else {
        return false;
      }
    })
    .catch((e) => {
      return false;
    });

  // return await axios
  //   .post(`${url}/api/v1/security/login`, submit_data)
  //   .then((res) => {
  //     if ("access_token" in res.data) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   })
  //   .catch((e) => {
  //     return false;
  //   });
};

export const steps = [
  {
    // state 0
    title: "My Account",
    formData: {},
    schema: {
      title: "User Profile",
      // "description": "ThinkBeyond Login",
      type: "object",
      required: [
        "email",
        "first_name",
        "last_name",
        "new_password",
        "confirm_password",
        "subscription",
      ],
      properties: {
        email: {
          type: "string",
          format: "email",
          title: "Email",
          minLength: 3,
        },
        first_name: {
          type: "string",
          title: "First Name",
          minLength: 3,
        },
        last_name: {
          type: "string",
          title: "Last  Name",
          minLength: 3,
        },
        mobile_phone: {
          type: "string",
          title: "Mobile Phone",
          minLength: 10,
        },
        new_password: {
          type: "string",
          format: "password",
          title: "New Password",
          minLength: 3,
        },
        confirm_password: {
          type: "string",
          format: "password",
          title: "Confirm Password",
          minLength: 3,
        },
        discount_code: {
          type: "string",
          title: "Discount Code"
        },
        subscription: {
          type: "string",
          title: "Subscription Period",
          oneOf: PayPalConfig.plans,
          default: "P-484914607H0770052MXLGR5Y",
        },
      },
    },
    ui_schema: {
      email: {
        "ui:autofocus": true,
        "ui:help": "Double check to make sure your email is typed correctly.",
        // "ui:description": "Login Username",
      },
      new_password: {
        "ui:widget": "password",
        "ui:help": "For your own protection, make it a strong password.",
      },
      confirm_password: {
        "ui:widget": "password",
        "ui:help": "",
      },
      discount_code: {
        "ui:help": "(If applicable) Discount will be applied at Verify stage.",
      },

      "ui:submitButtonOptions": {
        submitText: "Update",
        norender: false,
        props: {
          disabled: false,
          className: "btn btn-info",
        },
      },

      mobile_phone: {
        "ui:widget": "PhoneNumberInput",
      },
    },

    // nextState: (data) => {
    //   return data.recipient_type == 2 ? 4 : 3;
    // },
    validate: (data, trueHandler, falseHandler) => {
      userExists(data.email).then((res) => {
        var extra_errors = {};

        if (data.new_password !== data.confirm_password) {
          extra_errors["confirm_password"] = {
            __errors: ["Passwords much match!"],
          };
        }

        if (res) {
          extra_errors["email"] = {
            __errors: ["Email already exists!"],
          };
        }

        if (Object.keys(extra_errors).length !== 0) {
          falseHandler(extra_errors);
        } else {
          trueHandler();
        }
      });
    },
  },
  {
    // state 3
    title: "My Senior",
    formData: {},
    schema: {
      title: "Your Dependent Senior",
      // "description": "ThinkBeyond Login",
      type: "object",
      required: [
        "recipient_first_name",
        "recipient_last_name",
        "recipient_gender",
        "recipient_language",
        "recipient_birth_date",
        "recipient_nickname",
        // "recipient_poa_forms",  // Commented out for Beta testing.
      ],
      properties: {
        recipient_first_name: {
          type: "string",
          title: "First Name",
          minLength: 3,
        },
        recipient_last_name: {
          type: "string",
          title: "Last  Name",
          minLength: 3,
        },
        recipient_gender: {
          type: "number",
          title: "Gender",
          oneOf: [
            { const: 1, title: "Female" },
            {
              const: 2,
              title: "Male",
            },
          ],
          default: 1,
        },
        recipient_language: {
          type: "number",
          title: "Language",
          oneOf: [
            { const: 1, title: "English" },
            {
              const: 2,
              title: "French",
            },
          ],
          default: 1,
        },
        recipient_birth_date: {
          type: "string",
          title: "Birth Date ( yyyy-mm-dd )",
          format: "date",
          default: "1950-01-01",
        },
        recipient_nickname: {
          type: "string",
          title: "Recipient Nickname",
          minLength: 3,
        },
        recipient_poa_forms: {
          type: "array",
          title: "Proof of Authority",
          description:
            "Upload documented proof of your authority to make decisions for your dependent senior in PDF format.",
          items: {
            type: "string",
            format: "data-url",
          },
        },
      },
    },
    validate: (data, trueHandler, falseHandler) => {

      // assessPOAform


      // recipientExists(data.recipient).then((res) => {
      //   console.log("RECIPIENT EXISTS", res);
      //   if (res) {
      //     falseHandler({
      //       recipient_subdomain: {
      //         __errors: ["Recipient already exists!"],
      //       },
      //     });
      //   } else {
      trueHandler();
      //   }
      // });
    },
    ui_schema: {
      recipient_poa_forms: {
        "ui:options": {
          accept: ".pdf",
        },
      },
    },
  },
  // {
  //   // state 4
  //   title: "My Site",
  //   formData: {},
  //   schema: {
  //     title: "SeniorSynCare Site",
  //     // "description": "ThinkBeyond Login",
  //     type: "object",
  //     required: ["recipient_subdomain", "texting_setup"],
  //     properties: {
  //       recipient_subdomain: {
  //         type: "string",
  //         title: "Recipient Subdomain",
  //         description:
  //           'ClartityCare will set up an special web address in the form of "http://<subdomain>.waitingforgod.ca" for you to access the app.',
  //         minLength: 3,
  //       },
  //       texting_setup: {
  //         type: "string",
  //         title: "Texting Setup",
  //         default:
  //           '{"country": " ", "region": " ", "district": " ", "area_code": " ", "phone_number": " "}',
  //       },
  //     },
  //   },

  //   ui_schema: {
  //     texting_setup: {
  //       "ui:widget": "PhoneNumbersWidget",
  //     },
  //   },
  //   validate: (data, trueHandler, falseHandler) => {
  //     recipientExists(data.recipient_subdomain).then((res) => {
  //       var extra_errors = {};

  //       const phone_json = JSON.parse(data.texting_setup);
  //       if (phone_json.phone_number === " ") {
  //         extra_errors["texting_setup"] = {
  //           __errors: ["Please select a texting number!"],
  //         };
  //       }

  //       if (res) {
  //         extra_errors["recipient_subdomain"] = {
  //           __errors: ["Subdomain already taken!"],
  //         };
  //       }

  //       if (Object.keys(extra_errors).length !== 0) {
  //         falseHandler(extra_errors);
  //       } else {
  //         trueHandler();
  //       }
  //     });
  //   },
  // },
  {
    // state 5
    title: "Verify",
    type: "object",
    schema: {
      title: "Verify Your Information",
      description:
        "Once you press the Submit button below, a confirmation email will be sent to your email address when your new site is ready!",
      type: "object",
      // properties: {
      properties: {
        email: {
          type: "string",
          title: "User Email",
          readOnly: true,
        },
        first_name: {
          type: "string",
          title: "User First Name",
          readOnly: true,
        },
        last_name: {
          type: "string",
          title: "User Last Name",
          readOnly: true,
        },
        subscription: {
          type: "string",
          title: "Subscription Period",
          oneOf: PayPalConfig.plans,
          readOnly: true,
        },
        // new_password: {
        //   type: "string",
        //   title: "User Password",
        //   readOnly: true,
        // },
        recipient_first_name: {
          type: "string",
          title: "Recipient First Name",
          readOnly: true,
        },
        recipient_last_name: {
          type: "string",
          title: "Recipient Last Name",
          readOnly: true,
        },
        recipient_gender: {
          type: "number",
          title: "Gender",
          oneOf: [
            { const: 1, title: "Female" },
            {
              const: 2,
              title: "Male",
            },
          ],
          readOnly: true,
        },
        recipient_language: {
          type: "number",
          title: "Language",
          oneOf: [
            { const: 1, title: "English" },
            {
              const: 2,
              title: "French",
            },
          ],
          readOnly: true,
        },
        recipient_birth_date: {
          type: "string",
          title: "Recipeint Birth Date",
          readOnly: true,
        },
        recipient_nickname: {
          type: "string",
          title: "Recipient Short Name",
          readOnly: true,
        },
        recipient_poa_forms: {
          type: "array",
          title: "Proof of Authority",
          items: {
            type: "string",
            // format: "data-url",
          },
          readOnly: true,
        },
        // recipient_subdomain: {
        //   type: "string",
        //   title: "Recipient Subdomain",
        //   readOnly: true,
        // },
        // texting_setup: {
        //   type: "string",
        //   title: "Texting Setup",
        //   readOnly: true,
        // },
      },
    },

    ui_schema: {
      recipient_poa_forms: {
        "ui:widget": "FilesInfoWidget",
      },
      new_password: {
        "ui:widget": "password",
      },
      texting_setup: {
        "ui:widget": "PhoneNumbersWidget",
      },
    },
  },
];
